import React, { useState, useEffect, useRef, useCallback } from 'react'
import * as estilos from './MAgregarPersonalCSS'
import Boton from '../../../../../../Elementos/Boton'
import Input from '../../../../../../Elementos/Input'
import Opciones from '../../../../../../Elementos/Opciones'
import * as api from '../../../../../../Api/Api'
import * as fg from '../../../../../../Api/FuncionesGlobales'
import Persona from './../CrearPersona'
function MAgregarEmpresa (param) {
  const [estaProcesando, setestaProcesando] = useState(false)
  const [estadoOpcion, setEstadoOpcion] = useState(
    (function () {
      if (param.tipo === 'editar') {
        if (Object.keys(param.param['productos']).length !== 1) {
          return 'NULL'
        }
      }
    })()
  )
  const [propietarioOp, setPropietarioOp] = useState([
    { codigo: "S", dsc: 'Si' },
    { codigo: "N", dsc: 'No' }
  ])

  const [tdocop, settdocop] = useState(null)
  const [empop, setempop] = useState(null)

  const [rolin, setrolin] = useState('')
  const [nombreIn, setNombreIn] = useState('')
  const [apemIn, setapemin] = useState('')
  const [apepin, setapepin] = useState('')
  const [tdocin, settdocin] = useState('')
  const [ndocin, setndocin] = useState('')
  const [empin, setempin] = useState('')
  const [ncelin, setncelin] = useState('')
  const [emailin, setemailin] = useState('')
  const [habilitadoIn, setHabilitadoIn] = useState('')
  const [envioForm, setEnvioForm] = useState(false)
  const [codigoElem, setCodigoElem] = useState(null)

  useEffect(() => {
    console.log(param)
    const inicio=async ()=>{
      const tdocCall = await api.listarTiposDocumentos();
      const empCall = await api.listarEmpresas(null,'S',null)
      const rolCall = await api.listarRoles()
      
      if(tdocCall && empCall && rolCall){
        if(tdocCall.SMListaErrores.length === 0 &&
          empCall.SMListaErrores.length === 0 &&
          rolCall.SMListaErrores.length === 0
        ){
          settdocop(tdocCall.tipoDocumento)
          setempop(empCall.empresa)
          setrolin(rolCall.roles.find(rp => rp.codigo === "PRVT01").id)
        }
        
      }
    } 
    inicio()
    if(param.codigoElem !== null){
      
    }
    
  }, [])

  const cambiarEstadoOpcion = event => {
    setEstadoOpcion(event.target.value)
    setHabilitadoIn(event.target.value)
  }
  return (
    <>
      <div id='baseModal' style={estilos.baseModal(param.esComputadora)}>
        <div
          id='contenedorModal'
          style={estilos.contenedorModal(param.esComputadora)}
        >
          <div style={estilos.botonCerrarModalStyle(param.esComputadora)}>
            <Boton
              style={{
                backgroundColor: '#E12121',
                borderRadius: param.esComputadora ? '0.5vw' : '1vw',
                padding: param.esComputadora ? '0.8vw' : '2vw'
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: param.esComputadora ? '1vw' : '2.5vw'
              }}
              texto='X'
              tipo='submit'
              tonalidad='20'
              estaProcesando={estaProcesando}
              onClick={() => {
                param.cerrarModal()
              }}
            />
          </div>
          <div style={estilos.tituloCSS(param.esComputadora)}>
            {param.tipoAccion === 'agregarEmpresa'
              ? 'Agregar/Registrar Personal'
              : 'Editar Personal'}
          </div>
          <div
            id='contenidoModal'
            style={estilos.contenidoModal(param.esComputadora)}
          >
            <div style={estilos.formularioCSS(param.esComputadora)}>
              <Persona esComputadora={param.esComputadora}/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MAgregarEmpresa
