import React, { useState, useEffect } from 'react'
import Input from '../../../../../Elementos/Input'
import Boton from '../../../../../Elementos/Boton'

import Opciones from '../../../../../Elementos/Opciones'
import {
  listarRoles,
  listarTiposDocumentos,
  listarDepartamentos,
  listarProvincias,
  listarDistritos,
  listaDistribuidores,
  listarMaestros,
  Persona,
  crearPersona,
  turnos,
  formasPago,
  registrarFormaPago,
  listarEmpresas
} from '../../../../../Api/Api'

import * as fg from './../../../../../Api/FuncionesGlobales'

import * as e from './CrearPersonaCSS'

function CrearPersona (datos) {
  const [documentos, setDocumento] = useState([])
  const [roles, setRoles] = useState([])
  const [departamentos, setDepartamentos] = useState([])
  const [provincias, setProvincias] = useState([])
  const [distritos, setDistritos] = useState([])
  const [distribuidores, setDistribuidores] = useState([])
  const [maestros, setmaestros] = useState([])

  const [departamentoSelec, setDepartamentoSelec] = useState(0)
  const [provinciaSelec, setProvinciaSelec] = useState(0)
  const [distritoSelec, setDistritoSelec] = useState(0)
  const [rolSelec, setRolSelec] = useState(0)
  const [docSelec, setDocSelec] = useState(0)
  const [distSelec, setDistSelec] = useState('')
  const [formaPagoSelec, setFormaPagoSelec] = useState(0)
  const [turnoSelect, setTurnoSelec] = useState(0)
  const [estaProcesando, setestaProcesando] = useState(false)
  //DATOS PERSONA
  const [nombres, setNombres] = useState('')
  const [apePaterno, setApePaterno] = useState('')
  const [apeMaterno, setApeMaterno] = useState('')
  const [nroDoc, setNroDoc] = useState('')
  const [nroCel, setNroCel] = useState('')
  const [direccion, setDireccion] = useState('')
  const [email, setEmail] = useState('')
  const [ubigeo, setUbigeo] = useState('')
  const [fecNac, setFecNac] = useState('')
  const [montoPagar, setMontoPagar] = useState(0)
  //ENVIO
  const [envioForm, setEnvioForm] = useState(false)
  const [erroresModal, setErroresModal] = useState(null)
  const [modalDetalle, setModalDetalle] = useState(false)
  const [respModalDet, setRespModalDet] = useState(null)
  const [turnosData, setTurnosData] = useState([])
  const [formasPagoData, setFormasPagoData] = useState([])
  const [limpiar, setLimpiar] = useState(false)
  const [lstEmpresas, setLstEmpresas] = useState([])
  const [empSelec,setEmpSelec]= useState(0)
  useEffect(() => {
    
    const listarOpciones = async () => {
      const respuesta = await listarRoles()
      const respuesta2 = await listarTiposDocumentos()
      const respuesta3 = await listarDepartamentos(604)
      const respuesta4 = await turnos()
      const respuesta5 = await formasPago()
      const respuesta6 = await listarEmpresas(null,'S','N')
      if (respuesta && respuesta2 && respuesta3 && respuesta4 && respuesta5 && respuesta6) {
        if (respuesta.SMListaErrores.length === 0) {
          setRoles(respuesta.roles)
        } else {
          setRoles(null)
        }
        if (respuesta2.SMListaErrores.length === 0) {
          setDocumento(respuesta2.tipoDocumento)
        } else {
          setDocumento(null)
        }
        if (respuesta3.SMListaErrores.length === 0) {
          setDepartamentos(respuesta3.Departamentos)
        } else {
          setDepartamentos(null)
        }
        if (respuesta4.SMListaErrores.length === 0) {
          setTurnosData(respuesta4.turnos)
        } else {
          setTurnosData(null)
        }
        if (respuesta5.SMListaErrores.length === 0) {
          setFormasPagoData(respuesta5.formasPago)
        } else {
          setFormasPagoData(null)
        }
        if (respuesta6.SMListaErrores.length === 0) {
          //console.log(respuesta6)
          setLstEmpresas(respuesta6.empresa)
        } else {
          setLstEmpresas(null)
        }
      }
      
    }
    listarOpciones()
  }, [])

  useEffect(() => {
    setProvincias([])
    setDistritos([])

    const listarOpciones = async () => {
      const respuesta = await listarProvincias(604, departamentoSelec)

      if (respuesta) {
        if (respuesta.SMListaErrores.length === 0) {
          setProvincias(respuesta.Provincias)
        } else {
          //setRoles(null)
        }
      }
    }
    listarOpciones()
  }, [departamentoSelec])

  useEffect(() => {
    setDistritos([])
    const listarOpciones = async () => {
      const respuesta = await listarDistritos(
        604,
        departamentoSelec,
        provinciaSelec
      )
      if (respuesta) {
        if (respuesta.SMListaErrores.length === 0) {
          setDistritos(respuesta.Distritos)
        } else {
          //setRoles(null)
        }
      }
    }
    listarOpciones()
  }, [provinciaSelec])

  const cambiaCampoClick = event => {
    switch (event.currentTarget.id) {
      case 'nombres':
        setNombres(event.currentTarget.value)
        break
      case 'apePaterno':
        setApePaterno(event.currentTarget.value)
        break
      case 'apeMaterno':
        setApeMaterno(event.currentTarget.value)
        break
      case 'numeroDocumento':
        setNroDoc(event.currentTarget.value)
        break
      case 'numCelular':
        setNroCel(event.currentTarget.value)
        break
      case 'direccion':
        setDireccion(event.currentTarget.value)
        break
      case 'correoElectronico':
        setEmail(event.currentTarget.value)
        break
      case 'montoPagar':
        setMontoPagar(event.currentTarget.value)
        break
      default:
    }
  }
  const cambioFecNac = event => {
    setFecNac(event.currentTarget.value)
  }
  const documentoClick = event => {
    setDocSelec(isNaN(parseInt(event)) ? '' : parseInt(event))
  }
  const formaPagoClick = event => {
    setFormaPagoSelec(event)
  }
  const turnoClick = event => {
    setTurnoSelec(isNaN(parseInt(event)) ? '' : parseInt(event))
  }
  const departamentosClick = event => {
    setDepartamentoSelec(isNaN(parseInt(event)) ? 0 : parseInt(event))
  }
  const provinciasClick = event => {
    setProvinciaSelec(isNaN(parseInt(event)) ? 0 : parseInt(event))
  }
  const distritosClick = event => {
    setDistritoSelec(isNaN(parseInt(event)) ? 0 : parseInt(event))
  }
  useEffect(() => {
    const listarOpciones = async () => {
      try {
        setUbigeo(
          distSelec !== '' ||
            distSelec !== null ||
            departamentoSelec !== '' ||
            departamentoSelec !== null ||
            provinciaSelec !== '' ||
            provinciaSelec !== null
            ? departamentoSelec.toString().padStart(2, '0') +
                '' +
                provinciaSelec.toString().padStart(2, '0') +
                '' +
                distritoSelec.toString().padStart(2, '0')
            : ''
        )
      } catch (excepcion) {}
    }
    listarOpciones()
  }, [distritoSelec])

  const distribuidoresClick = event => {
    console.log(event)
    setDistSelec(event)
  }
  const rolClick = event => {
    setRolSelec(event)
    console.log(event)
    if(event === 'PRVT01' || event === 'CLTN01'){
      setFecNac('0001-01-01')
      setDepartamentoSelec(1)
      setProvinciaSelec(1)
      setDistritoSelec(1)
      setDireccion("--")
    }
  }
  const empresasClick = event =>{
    setEmpSelec(event)
    console.log(event)
  }

  useEffect(() => {
    console.log(rolSelec,"useEffect")
    const listarOpciones = async () => {
      if (rolSelec === 'ADIS01'){
        const respuesta = await listaDistribuidores()
        if (respuesta) {
          if (respuesta.SMListaErrores.length === 0) {
            setDistribuidores(respuesta.Distribuidores)
            console.log(respuesta.Distribuidores)
          } else {
            //setRoles(null)
          }
        }
      }
      else if (rolSelec === 'AMST01'){
        const respuesta = await listarMaestros(null, "S")
        if (respuesta) {
          if (respuesta.SMListaErrores.length === 0) {
            setmaestros(respuesta.maestro)
            console.log(respuesta.maestro)
          } else {
            //setRoles(null)
          }
        }
      }
    }
    listarOpciones()
  }, [rolSelec])

  const enviarCrearPersonaClick = async event => {
    event.preventDefault()
    setEnvioForm(true)

    let rolSelecId = roles.filter(e => {
      return e.codigo === rolSelec
    })[0]
      ? roles.filter(e => {
          return e.codigo === rolSelec
        })[0].id
      : 0
    console.log(ubigeo)
    console.log(rolSelec)
    console.log(empSelec)

    if (
      nroDoc === null ||
      nroDoc === '' ||
      nombres === null ||
      nombres === '' ||
      apePaterno === null ||
      apePaterno === '' ||
      apeMaterno === null ||
      apeMaterno === '' ||
      docSelec === 0 ||
      docSelec === null ||
      docSelec === '' ||
      isNaN(docSelec) ||
      fecNac === null ||
      fecNac === '' ||
      ubigeo === null ||
      ubigeo === '' ||
      direccion === null ||
      direccion === '' ||
      //email === null || email=== '' ||
      nroCel === null ||
      nroCel === '' ||
      departamentoSelec === 0 ||
      departamentoSelec === '' ||
      departamentoSelec === null ||
      isNaN(departamentoSelec) ||
      provinciaSelec === 0 ||
      provinciaSelec === '' ||
      provinciaSelec === null ||
      isNaN(provinciaSelec) ||
      distritoSelec === 0 ||
      distritoSelec === '' ||
      distritoSelec === null ||
      isNaN(distritoSelec) ||
      rolSelecId === 0 ||
      rolSelecId === '' ||
      rolSelecId === null ||
      isNaN(rolSelecId) ||
      (rolSelec === 'PRVT01' && empSelec === '')
    ) {
      setErroresModal([
        { descripcionError: 'Complete el/los campos marcados en rojo' }
      ])
      setModalDetalle(true)
    } else {
      if (
        (['ADIS01','AMST01'].includes(rolSelec) &&
          (distSelec === '' || distSelec === 0 || distSelec === null)) ||
        (rolSelec !== 'ADIS01' &&
          rolSelec !== 'DIST01' &&
          rolSelec !== 'PRVT01' &&
          rolSelec !== 'CLTN01' &&
          (montoPagar === 0 ||
            montoPagar === '' ||
            isNaN(montoPagar) ||
            montoPagar === null))
      ) {
        setErroresModal([
          { descripcionError: 'Complete el/los campos marcados en rojo' }
        ])
        setModalDetalle(true)
        return null
      }
      const persona = new Persona(
        nroDoc,
        nombres,
        apePaterno,
        apeMaterno,
        docSelec,
        fecNac,
        ubigeo,
        direccion,
        email, // correoElectronico
        nroCel,
        '',
        'recibo.jpg'
      )

      setestaProcesando(true)
      const respuesta = await crearPersona(
        rolSelec === 'PRVT01' ? empSelec:1, 1, persona, rolSelecId, distSelec
      )
      if (respuesta) {
        if (respuesta.SMListaErrores.length == 0) {
          setModalDetalle(true)
          setRespModalDet(respuesta)
          setestaProcesando(false)
          //se registran los pagos una vez que no tiene errores
          if (rolSelec !== 'DIST01' && rolSelec !== 'ADIS01' && rolSelec !== 'PRVT01' && rolSelec !== 'CLTN01') {
            const respuesta2 = await registrarFormaPago(
              respuesta.codigoUsuario,
              montoPagar,
              formaPagoSelec,
              turnoSelect
            )
            if (respuesta2) {
              console.log(respuesta2)
            }
          }
        } else {
  
          setModalDetalle(true)
          setErroresModal(respuesta.SMListaErrores)
          setestaProcesando(false)
        }
      }
    }
  }
  useEffect(() => {
    if (limpiar) setLimpiar(false)
  }, [limpiar])

  const limpiarFormulario = async event => {
    const form = document.getElementById('formularioPersona')
    const nombres = document.getElementById('nombres')
    setLimpiar(true)

    setNombres('')
    setApePaterno('')
    setApeMaterno('')
    setNroDoc('')
    setNroCel('')
    setDireccion('')
    setEmail('')
    setFecNac('')
    setFecNac('')
    setDocSelec(0)
    setDepartamentoSelec(0)
    setProvinciaSelec(0)
    setDistritoSelec(0)
    setMontoPagar('')
    setFormaPagoSelec(0)
    setTurnoSelec(0)
    setRolSelec(0)
    form.reset()
    //event.target.form.reset();
  }

  const cerrarModalClick = () => {
    setModalDetalle(false)
    setErroresModal(null)
  }
  return (
    <>
      {modalDetalle ? (
        <div id='modalStyle' style={e.modalStyle(datos.esComputadora)}>
          <div
            id='e.contContenidoModalStyle'
            style={e.contContenidoModalStyle(datos.esComputadora)}
          >
            <div
              id='contenidoModalStyle'
              style={e.contenidoModalStyle(datos.esComputadora)}
            >
              <div id='cerrar' style={e.cerrarStyle()}>
                <Boton
                  style={{
                    backgroundColor: '#F52424',
                    borderRadius: datos.esComputadora ? '0.5vw':'1vw',
                    padding: datos.esComputadora ? '0.8vw':'2vw',
                   
                  }}
                  textoStyle={{
                    color: 'white',
                    width: '100%',
                    fontSize: datos.esComputadora ? '1vw' : '2.5vw'
                  }}
                  texto='X'
                  tipo='submit'
                  tonalidad='20'
                  estaProcesando={estaProcesando}
                  onClick={cerrarModalClick}
                />
              </div>
              {respModalDet !== null && respModalDet.exito === 'S' && (
                <div style={e.exitoStyle(datos.esComputadora)}>
                  <span style={e.labelStyle(datos.esComputadora)}>INFORMACIÓN </span>
                </div>
              )}
              <div style={e.respuestaStyle(datos.esComputadora)}>
                {erroresModal !== null ? (
                  erroresModal.map(i => {
                    return (
                      <div key={0} style={e.contenidoLabelStyle()}>
                        {i.descripcionError}
                      </div>
                    )
                  })
                ) : respModalDet !== null ? (
                  <>
                    {respModalDet.nombre && (
                      <div style={e.contLabelStyle(datos.esComputadora)}>
                        <span style={e.labelStyle(datos.esComputadora)}>Nombres: </span>
                        <span style={e.contenidoLabelStyle(datos.esComputadora)}>
                          {respModalDet.nombre}
                        </span>
                      </div>
                    )}
                    {respModalDet.codigoUsuario && (
                      <div style={e.contLabelStyle(datos.esComputadora)}>
                        <span style={e.labelStyle(datos.esComputadora)}>Usuario: </span>
                        <span style={e.contenidoLabelStyle(datos.esComputadora)}>
                          {respModalDet.codigoUsuario}
                        </span>
                      </div>
                    )}
                    {respModalDet.contrasenia && (
                      <div style={e.contLabelStyle(datos.esComputadora)}>
                        <span style={e.labelStyle(datos.esComputadora)}>Contraseña: </span>
                        <span style={e.contenidoLabelStyle(datos.esComputadora)}>
                          {respModalDet.contrasenia}
                        </span>
                      </div>
                    )}
                    {respModalDet.rol && (
                      <div style={e.contLabelStyle(datos.esComputadora)}>
                        <span style={e.labelStyle(datos.esComputadora)}>Rol: </span>
                        <span style={e.contenidoLabelStyle(datos.esComputadora)}>
                          {respModalDet.rol}
                        </span>
                      </div>
                    )}
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {false && <div style={e.cabeceraStyle(datos.esComputadora)}>Registrar Persona</div>}
      {false && (
        <div
          id='contCrearPersona'
          style={e.contCrearPersonaStyle(datos.esComputadora)}
        ></div>
      )}
      <form
        id='formularioPersona'
        style={e.contCrearPersonaStyle(datos.esComputadora)}
      >
        <div style={e.nombresStyle(datos.esComputadora)}>
          <Input
            style={{
              ...(envioForm && nombres === ''
                ? e.estiloVacio(datos.esComputadora, nombres)
                : {})
            }}
            id='nombres'
            moderno='Nombres'
            tipo='text'
            autoComplete
            onChange={cambiaCampoClick}
            esComputadora={datos.esComputadora}
            valorInicial={''}
            limpiar={limpiar}
          />
        </div>
        <div style={e.apePaternoStyle(datos.esComputadora)}>
          <Input
            style={{
              ...(envioForm && apePaterno === ''
                ? e.estiloVacio(datos.esComputadora, apePaterno)
                : {})
            }}
            id='apePaterno'
            moderno='Apellido Paterno'
            tipo='text'
            autoComplete
            onChange={cambiaCampoClick}
            esComputadora={datos.esComputadora}
            valorInicial={''}
            limpiar={limpiar}
          />
        </div>
        <div style={e.apeMaternoStyle(datos.esComputadora)}>
          <Input
            style={{
              ...(envioForm && apeMaterno === ''
                ? e.estiloVacio(datos.esComputadora, apeMaterno)
                : {})
            }}
            id='apeMaterno'
            moderno='Apellido Materno'
            tipo='text'
            autoComplete
            onChange={cambiaCampoClick}
            esComputadora={datos.esComputadora}
            valorInicial={''}
            limpiar={limpiar}
          />
        </div>
        <div style={e.numeroDocumentoStyle(datos.esComputadora)}>
          <Input
            style={{
              ...(envioForm && nroDoc === ''
                ? e.estiloVacio(datos.esComputadora, nroDoc)
                : {})
            }}
            id='numeroDocumento'
            moderno='Nro. Documento'
            tipo='number'
            autoComplete
            onChange={cambiaCampoClick}
            valorInicial={''}
            esComputadora={datos.esComputadora}
            maxDigitos='8'
            limpiar={limpiar}
          />
        </div>
        <div style={e.empStyle(datos.esComputadora,rolSelec)}>
          <Opciones
            style={{
              ...(envioForm && (empSelec === 0 || empSelec === '')
                ? e.estiloVacio(datos.esComputadora, empSelec)
                : {})
            }}
            formPersona='entra'
            moderno='Empresa'
            opciones={lstEmpresas !== null ? lstEmpresas : []}
            clave='codigo'
            valor={['nombre']}
            esComputadora={datos.esComputadora}
            onClick={empresasClick}
            limpiar={limpiar}
          />
        </div>
        <div style={e.fecNacStyle(datos.esComputadora,rolSelec)}>
          <Input
            style={{
              ...(envioForm && fecNac === ''
                ? e.estiloVacio(datos.esComputadora, fecNac)
                : {})
            }}
            id='fecNac'
            moderno='Fec. Nacimiento'
            tipo='date'
            autoComplete
            esComputadora={datos.esComputadora}
            onChange={cambioFecNac}
            valorInicial={''}
            limpiar={limpiar}
          />
        </div>
        <div style={e.codDocumentoStyle(datos.esComputadora)}>
          
          <Opciones
            style={{
              ...(envioForm && (docSelec === 0 || docSelec === '')
                ? e.estiloVacio(datos.esComputadora, docSelec)
                : {})
            }}
            formPersona='entra'
            moderno='Tipo Documento'
            opciones={documentos !== null ? documentos : []}
            clave='id'
            valor={['descripcion']}
            esComputadora={datos.esComputadora}
            onClick={documentoClick}
            limpiar={limpiar}
          />
        </div>
        <div style={e.codigoRolStyle(datos.esComputadora)}>
          <Opciones
            style={{
              ...(envioForm && (rolSelec === 0 || rolSelec === '')
                ? e.estiloVacio(datos.esComputadora, rolSelec)
                : {})
            }}
            formPersona='entra'
            moderno='Rol'
            opciones={roles !== null ? roles : []}
            clave='codigo'
            valor={['descripcion']}
            esComputadora={datos.esComputadora}
            onClick={rolClick}
            limpiar={limpiar}
          />
        </div>
        
        <div style={e.codDepartamentoStyle(datos.esComputadora,rolSelec)}>
          <Opciones
            style={{
              ...(envioForm &&
              (departamentoSelec === 0 || departamentoSelec === '')
                ? e.estiloVacio(datos.esComputadora, departamentoSelec)
                : {})
            }}
            formPersona='entra'
            moderno='Departamento'
            opciones={departamentos !== null ? departamentos : []}
            clave='codDepartamento'
            valor={['descripcion']}
            esComputadora={datos.esComputadora}
            onClick={departamentosClick}
            limpiar={limpiar}
          />
        </div>
        
        <div style={e.codProvinciaStyle(datos.esComputadora,rolSelec)}>
          <Opciones
            style={{
              ...(envioForm && (provinciaSelec === 0 || provinciaSelec === '')
                ? e.estiloVacio(datos.esComputadora, provinciaSelec)
                : {})
            }}
            formPersona='entra'
            moderno='Provincia'
            opciones={provincias !== null ? provincias : []}
            clave='codProvincia'
            valor={['descripcion']}
            esComputadora={datos.esComputadora}
            onClick={provinciasClick}
            limpiar={limpiar}
          />
        </div>
        
        <div style={e.codDistritoStyle(datos.esComputadora,rolSelec)}>
          <Opciones
            style={{
              ...(envioForm && (distritoSelec === 0 || distritoSelec === '')
                ? e.estiloVacio(datos.esComputadora, distritoSelec)
                : {})
            }}
            formPersona='entra'
            moderno='Distrito'
            opciones={distritos !== null ? distritos : []}
            clave='codDistrito'
            valor={['descripcion']}
            esComputadora={datos.esComputadora}
            onClick={distritosClick}
            limpiar={limpiar}
          />
        </div>
        <div style={e.codDistribuidorStyle(datos.esComputadora, rolSelec)}>
          {console.log(distribuidores,maestros)}
            <Opciones
            formPersona='entra'
            moderno={['ADIS01'].includes(rolSelec) ? 'Distribuidor' : 'Maestro'}
            opciones={['ADIS01'].includes(rolSelec) ? distribuidores !== null ? distribuidores : [] : maestros !== null ? maestros : []}
            clave={['ADIS01'].includes(rolSelec) ? 'codUsuario' : 'usuario'}
            valor={['ADIS01'].includes(rolSelec) ? ['Nombre', 'ApellidoPaterno'] : ['AMST01'].includes(rolSelec)?['nombre', 'apellido_paterno']:[]}
            esComputadora={datos.esComputadora}
            onClick={distribuidoresClick}
            limpiar={limpiar}
          />
          
        </div>
        

        <div style={e.direccionStyle(datos.esComputadora,rolSelec)}>
          <Input
            style={{
              ...(envioForm && direccion === ''
                ? e.estiloVacio(datos.esComputadora, direccion)
                : {})
            }}
            id='direccion'
            moderno='Dirección'
            tipo='text'
            autoComplete
            onChange={cambiaCampoClick}
            esComputadora={datos.esComputadora}
            valorInicial={''}
            limpiar={limpiar}
          />
        </div>
        <div style={e.correoElectronicoStyle(datos.esComputadora)}>
          <Input
            style={{
              /*...(envioForm && email === ''
                ? e.estiloVacio(datos.esComputadora, email)
                : {})
                */
            }}
            id='correoElectronico'
            moderno='Email'
            tipo='text'
            autoComplete
            esComputadora={datos.esComputadora}
            onChange={cambiaCampoClick}
            valorInicial={''}
            limpiar={limpiar}
          />
        </div>
        <div style={e.numCelularStyle(datos.esComputadora)}>
          <Input
            style={{
              ...(envioForm && nroCel === ''
                ? e.estiloVacio(datos.esComputadora, nroCel)
                : {})
            }}
            id='numCelular'
            moderno='Nro. Celular'
            tipo='number'
            autoComplete
            onChange={cambiaCampoClick}
            valorInicial={''}
            esComputadora={datos.esComputadora}
            maxDigitos='9'
            limpiar={limpiar}
          />
        </div>
        
        <div style={e.pagoCSS(datos.esComputadora, rolSelec)}>Definir Pago</div>
        <div style={e.pagoValorCSS(datos.esComputadora, rolSelec)}>
          <div style={e.montoPagarCSS(datos.esComputadora)}>
            <Input
              style={{
                ...(envioForm && (montoPagar === 0 || montoPagar === '')
                  ? e.estiloVacio(datos.esComputadora, montoPagar)
                  : {})
              }}
              id='montoPagar'
              moderno='Monto a pagar'
              tipo='number'
              autoComplete
              onChange={cambiaCampoClick}
              valorInicial={''}
              esComputadora={datos.esComputadora}
              maxDigitos='7'
              limpiar={limpiar}
            />
          </div>
          <div style={e.formaPagoCSS(datos.esComputadora)}>
            <Opciones
              style={{
                ...(envioForm && (formaPagoSelec === 0 || formaPagoSelec === '')
                  ? e.estiloVacio(datos.esComputadora, formaPagoSelec)
                  : {})
              }}
              formPersona='entra'
              moderno='Forma de Pago'
              opciones={formasPagoData !== null ? formasPagoData : []}
              clave='codFormaPago'
              valor={['descripcion']}
              esComputadora={datos.esComputadora}
              onClick={formaPagoClick}
              limpiar={limpiar}
            />
          </div>
          <div style={e.turnoCSS(datos.esComputadora)}>
            <Opciones
              style={{
                ...(envioForm && (turnoSelect === 0 || turnoSelect === '')
                  ? e.estiloVacio(datos.esComputadora, turnoSelect)
                  : {})
              }}
              formPersona='entra'
              moderno='Turno'
              opciones={turnosData !== null ? turnosData : []}
              clave='id'
              valor={['nombre']}
              esComputadora={datos.esComputadora}
              onClick={turnoClick}
              limpiar={limpiar}
            />
          </div>
        </div>
        <div style={e.botonEnviarStyle(datos.esComputadora, rolSelec)}>
          <div style={e.botonCrearPersonaCSS(datos.esComputadora)}>
            <Boton
              style={{
                backgroundColor: '#2D8B0B',
                borderRadius: datos.esComputadora ? '0.5vw' : '1vw',
                padding: datos.esComputadora ? '0.8vw' : '2vw'
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: datos.esComputadora ? '1vw' : '2.5vw'
              }}
              texto='Crear Persona'
              tipo='submit'
              tonalidad='20'
              estaProcesando={estaProcesando}
              onClick={enviarCrearPersonaClick}
            />
          </div>
          <div style={e.botonLimpiarDatosCSS(datos.esComputadora)}>
            <Boton
              style={{
                backgroundColor: '#F52424',
                borderRadius: datos.esComputadora ? '0.5vw' : '1vw',
                padding: datos.esComputadora ? '0.8vw' : '2vw'
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: datos.esComputadora ? '1vw' : '2.5vw'
              }}
              texto='Limpiar Datos'
              tipo='reset'
              tonalidad='20'
              estaProcesando={estaProcesando}
              onClick={limpiarFormulario}
            />
          </div>
        </div>
      </form>
    </>
  )
}

export default CrearPersona
