import { useEffect, useState, useContext } from 'react'
import { Contexto } from '../../../../index'
import * as e from './PersonalCSS'
import * as api from '../../../../../../Api/Api'
import * as fg from '../../../../../../Api/FuncionesGlobales'
import agregar from './../../../../../../Imagenes/Iconos/agregar.png'
import editar from './../../../../../../Imagenes/Iconos/editar.png'
import borrar from './../../../../../../Imagenes/Iconos/borrar.png'
import Boton from '../../../../../../Elementos/Boton'
import Input from '../../../../../../Elementos/Input'
import MAgregarEmpresa from './MAgregarPersonal'
import MEliminarEmpresa from './MEliminarPersonal'

function Personal () {
  const { esComputadora } = useContext(Contexto)

  const [listado, setListado] = useState([])
  const [listadoOriginal, setListadoOriginal] = useState([])
  const [fnomape, setfnomape] = useState('')
  const [fndoc, setfndoc] = useState('')
  const [fusuario, setfusuario] = useState('')
  //eventos
  const [elemSelec, setElemSelec] = useState(null)
  const [dscElemSelec, setDscElemSelec] = useState(null)
  //modales
  const [verModal, setVerModal] = useState(false)
  const [tipoAccion, setTipoAccion] = useState('')

  useEffect(() => {
    if (!verModal) {
      console.clear()
      const inicio = async () => {
        const listarEmpleadosCall = await api.listarPersonas()
        console.log(listarEmpleadosCall)
        if (listarEmpleadosCall) {
          if (listarEmpleadosCall.SMListaErrores.length === 0) {
            if(fnomape ==='' && fndoc === '' && fusuario === ''){
              console.log("entre a actualizar listado")
              setListado(listarEmpleadosCall.SMListaPersonas)
            }
            setListadoOriginal(listarEmpleadosCall.SMListaPersonas)
          }
        }
      }
      inicio()
    }
  }, [verModal])

  const cerrarModal = e => {
    setVerModal(false)
  }

  useEffect(() => {
    console.log(fnomape, fndoc, fusuario)
    setListado(
      listadoOriginal.filter(
        e =>
          (fnomape === ''
            ? true
            : (
                e.nombres.normalize('NFD').replace(/[\u0300-\u036f]/g, '') +
                ' ' +
                e.apePaterno.normalize('NFD').replace(/[\u0300-\u036f]/g, '') +
                ' ' +
                e.apeMaterno.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
              )
                .toUpperCase()
                .trim()
                .includes(fnomape)) &&
          (fndoc === ''
            ? true
            : e.numeroDocumento.toString().trim().includes(fndoc)) &&
          (fusuario === ''
            ? true
            : e.codigoUsuario !== null
            ? e.codigoUsuario.trim().includes(fusuario)
            : false)
      )
    )
  }, [fnomape, fndoc, fusuario])
  return (
    <>
      {verModal && (
        <div>
          {tipoAccion === 'agregarEmpresa' || tipoAccion === 'editarEmpresa' ? (
            <MAgregarEmpresa
              tipoAccion={tipoAccion}
              cerrarModal={cerrarModal}
              esComputadora={esComputadora}
              codigoElem={elemSelec}
            />
          ) : (
            <MEliminarEmpresa
              dscElemSelec={dscElemSelec}
              tipoAccion={tipoAccion}
              cerrarModal={cerrarModal}
              esComputadora={esComputadora}
              codigoElem={elemSelec}
            />
          )}
        </div>
      )}
      <div id='contenedor' style={e.contenedor(esComputadora)}>
        <div id='titulo' style={e.titulo(esComputadora)}>
          Personal
        </div>
        <div style={e.filtros(esComputadora)}>
          <div style={{ padding: '0.1vw' }}>
            <Input
              style={{}}
              textoStyle={{}}
              id='fnomape'
              moderno={'Nombre y/o Apellido'}
              tipo='text'
              onChange={e => {
                setfnomape(e.currentTarget.value.toUpperCase().trim())
                //filtrosNomApe(e.currentTarget.value)
              }}
              valorInicial={''}
              esComputadora={esComputadora}
              limpiar={fnomape === '' ? true: false}
            />
          </div>
          <div style={{ padding: '0.1vw' }}>
            <Input
              style={{}}
              textoStyle={{}}
              id='fndoc'
              moderno={'Nro. Documento'}
              tipo='number'
              onChange={e => {
                setfndoc(e.currentTarget.value.toUpperCase().trim())
                //filtrosNomApe(e.currentTarget.value)
              }}
              valorInicial={''}
              esComputadora={esComputadora}
              limpiar={fndoc === '' ? true: false}
            />
          </div>
          <div style={{ padding: '0.1vw' }}>
            <Input
              style={{}}
              textoStyle={{}}
              id='fusuario'
              moderno={'Usuario'}
              tipo='text'
              onChange={e => {
                setfusuario(e.currentTarget.value.toUpperCase().trim())
                //filtrosNomApe(e.currentTarget.value)
              }}
              valorInicial={''}
              esComputadora={esComputadora}
              limpiar={fusuario === '' ? true: false}
            />
          </div>
          <div style={{ padding: '0.1vw', display: "flex", alignItems: "center",justifyContent: "center" }}>
            <Boton
              style={{
                backgroundColor: '#E12121',
                borderRadius: esComputadora ? '0.5vw' : '1vw',
                padding: esComputadora ? '0.8vw' : '1vw',
                
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: esComputadora ? '1vw' : '2.5vw',
                padding: "0"
              }}
              texto='Limpiar Filtros'
              tipo='submit'
              estaProcesando={false}
              onClick={event => {
                event.preventDefault()
                setfnomape('')
                setfndoc('')
                setfusuario('')
                
              }}
            />
          </div>
        </div>
        <div id='centro' style={e.centro(esComputadora)}>
          <div id='listado' style={e.listado(esComputadora)}>
            <div id='cabeceraListado' style={e.cabeceraListado(esComputadora)}>
              <div
                style={{
                  borderLeft: '0.1vw solid black',
                  ...e.elemCabListado(esComputadora)
                }}
              >
                Nombres y Apellidos
              </div>
              <div style={e.elemCabListado(esComputadora)}>Nro. Documento</div>
              <div style={e.elemCabListado(esComputadora)}>Nro. Celular</div>
              <div style={e.elemCabListado(esComputadora)}>Usuario</div>
              <div style={e.elemCabListado(esComputadora)}>Correo</div>
              <div style={e.elemCabListado(esComputadora)}>Dirección</div>
              <div style={e.elemCabListado(esComputadora)}>Activo</div>
              <div style={e.elemCabListado(esComputadora)}>Opciones</div>
            </div>
            {listado.length > 0 &&
              listado.map(emp => (
                <div
                  key={emp.numeroDocumento}
                  id={emp.numeroDocumento}
                  onMouseEnter={() => {
                    setElemSelec(emp.numeroDocumento)
                  }}
                  onMouseLeave={() => {
                    setElemSelec(null)
                  }}
                  style={e.cuerpoListado(esComputadora)}
                >
                  <div
                    style={{
                      ...e.elemCuerpoListado(esComputadora),
                      ...(emp.activo === 'N' && e.inactivoElem(esComputadora)),
                      ...(elemSelec === emp.numeroDocumento &&
                        e.elemCuerpoHoverListado(esComputadora))
                      //justifyContent: "left"
                    }}
                  >
                    {fg.capitalizar_palabra(
                      emp.nombres + ' ' + emp.apePaterno + ' ' + emp.apeMaterno
                    )}
                  </div>

                  <div
                    style={{
                      ...e.elemCuerpoListado(esComputadora),
                      ...(emp.activo === 'N' && e.inactivoElem(esComputadora)),
                      ...(elemSelec === emp.numeroDocumento &&
                        e.elemCuerpoHoverListado(esComputadora))
                    }}
                  >
                    {emp.numeroDocumento}
                  </div>
                  <div
                    style={{
                      ...e.elemCuerpoListado(esComputadora),
                      ...(emp.activo === 'N' && e.inactivoElem(esComputadora)),
                      ...(elemSelec === emp.numeroDocumento &&
                        e.elemCuerpoHoverListado(esComputadora))
                    }}
                  >
                    {emp.numCelular}
                  </div>
                  <div
                    style={{
                      ...e.elemCuerpoListado(esComputadora),
                      ...(emp.activo === 'N' && e.inactivoElem(esComputadora)),
                      ...(elemSelec === emp.numeroDocumento &&
                        e.elemCuerpoHoverListado(esComputadora))
                    }}
                  >
                    {[null, ''].includes(emp.codigoUsuario)
                      ? 'No asignado'
                      : emp.codigoUsuario}
                  </div>
                  <div
                    style={{
                      ...e.elemCuerpoListado(esComputadora),
                      ...(emp.activo === 'N' && e.inactivoElem(esComputadora)),
                      ...(elemSelec === emp.numeroDocumento &&
                        e.elemCuerpoHoverListado(esComputadora))
                    }}
                  >
                    {[null, '', 'NONE'].includes(emp.correoElectronico)
                      ? 'No registrado'
                      : emp.correoElectronico}
                  </div>
                  <div
                    style={{
                      ...e.elemCuerpoListado(esComputadora),
                      ...(emp.activo === 'N' && e.inactivoElem(esComputadora)),
                      ...(elemSelec === emp.numeroDocumento &&
                        e.elemCuerpoHoverListado(esComputadora))
                    }}
                  >
                    {emp.direccion === '' ? 'No registrado' : emp.direccion}
                  </div>
                  <div
                    style={{
                      ...e.elemCuerpoListado(esComputadora),
                      ...(emp.activo === 'N' && e.inactivoElem(esComputadora)),
                      ...(elemSelec === emp.numeroDocumento &&
                        e.elemCuerpoHoverListado(esComputadora))
                    }}
                  >
                    {emp.activo === 'S' ? 'Si' : 'No'}
                  </div>
                  <div
                    style={{
                      ...e.elemCuerpoOpListado(esComputadora),
                      ...(emp.activo === 'N' && e.inactivoElem(esComputadora)),
                      ...(elemSelec === emp.numeroDocumento &&
                        e.elemCuerpoHoverListado(esComputadora))
                    }}
                  >
                    <img
                      src={editar}
                      style={e.img(esComputadora)}
                      onClick={e => {
                        setVerModal(true)
                        setTipoAccion('editarEmpresa')
                      }}
                    />
                    <img
                      src={borrar}
                      style={e.img(esComputadora)}
                      onClick={e => {
                        setDscElemSelec(
                          e.currentTarget.parentNode.parentNode
                            .firstElementChild.innerHTML
                        )
                        setVerModal(true)
                        setTipoAccion('eliminarEmpresa')
                      }}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div style={e.opciones(esComputadora)}>
          <div>
            <Boton
              style={{
                backgroundColor: '#2D8B0B',
                borderRadius: esComputadora ? '0.5vw' : '1vw',
                padding: esComputadora ? '0.8vw' : '2vw'
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: esComputadora ? '1vw' : '2.5vw'
              }}
              texto='Agregar Personal'
              tipo='submit'
              estaProcesando={false}
              id='agregarEmpresa'
              onClick={e => {
                setVerModal(true)
                setTipoAccion(e.currentTarget.id)
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Personal
