import { useEffect, useState, useContext } from 'react'
import { Contexto } from './../Componentes/Principal/index'
import Boton from './Boton'
import * as e from './ModalGenericoCSS'
import * as fg from './../Api/FuncionesGlobales'

function ModalGenerico (param) {
  const { esComputadora } = useContext(Contexto)
  return (
    <>
      <e.Modal $capa={param.capa} $height={param.height} $width={param.width} $escomputadora={esComputadora}>
        <div className='contenidoModal'>
          <div className='cabecera'>
            <span>{param.titulo} </span>
            <span >
              <Boton
                style={{
                  backgroundColor: '#E12121',
                  borderRadius: esComputadora ? '0.5vw' : '1.5vw',
                  height: esComputadora ? "2.5vw":"5vw"
                  //padding: esComputadora ? '0.8vw' : '2vw'
                }}
                textoStyle={{
                  color: 'white',
                  width: '100%',
                  fontSize: esComputadora ? '1vw' : '2.5vw'
                }}
                texto='X'
                tipo='submit'
                //estaProcesando={estaProcesando}
                onClick={param.cerrarmodal ? param.cerrarmodal:()=>{}}
              />
            </span>
          </div>
          <div className='cuerpomodal'>{param.children}</div>
        </div>
      </e.Modal>
    </>
  )
}

export default ModalGenerico
