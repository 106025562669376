import React, { useState,useEffect } from 'react'
import { listaDistribuidores,listarAyudantesDistribuidor,listarPedidos,obtenerCookie,listarPersonal } from './../../../../Api/Api'
import Opciones from './../../../../Elementos/Opciones'
import DetalleRegistroVentaT from './DetalleRegistroVentaT'
import Boton from './../../../../Elementos/Boton'
import Input from './../../../../Elementos/Input'

import * as fg from './../../../../Api/FuncionesGlobales'

function RegistroVentasTienda (datos) {
  const [isFocused, setIsFocused] = useState(false)
  const [distribuidores, setDistribuidores] = useState(null)
  const [distribuidorSeleccionado, setDistribuidorSeleccionado] = useState(null)
  const [ayudantes, setAyudantes] = useState(null)
  const [ayuDistSelec, setayuDistSelec] = useState(null)
  const [pedidos, setPedidos] = useState(null)
  const [isHovered, setIsHovered] = useState(false)
  const [idHovered, setIdHovered] = useState('')
  const [codPedidos, setCodPedidos] = useState({})    
  const [idSeleccionado, setIdSeleccionado] = useState(null)
  const [distSeleccionadoModal, setdistSeleccionadoModal] = useState(null)
  const [coddistSeleccionadoModal, setcoddistSeleccionadoModal] = useState(null)
  const [fecSolSeleccionadoModal, setfecSolSeleccionadoModal] = useState(null)
  const [modalDetalle, setModalDetalle] = useState(false)
  const [rol, setRol] = useState(obtenerCookie("descRol")!==null ? obtenerCookie("descRol").toUpperCase().trim():"")
  const [codUsuarioSesion, setcodUsuarioSesion] = useState(obtenerCookie("codUsuario")!==null ? obtenerCookie("codUsuario").toUpperCase().trim():"")
  const [personal, setPersonal] = useState(null)
  const [deudaTotal, setDeudaTotal] = useState(null)
  const [fecRecojo, setfecRecojo] = useState(null)

  useEffect(() => {
    
    const listaDistribuidoresData = async () => {
        const respuesta = await listarPersonal(); // Esta función debe retornar el resultado que necesitas para la validación
        
        const codUsuarioTemporal = rol === "SUPER ADMINISTRADOR" ? '-1':codUsuarioSesion
        const respuesta2 = await listarPedidos(codUsuarioTemporal,1,1);
        
        if(respuesta.SMListaErrores.length ===0){
          setPersonal(respuesta.Empleados)          
        }
        if(respuesta2.SMListaErrores.length ===0){
          setPedidos(respuesta2.Pedidos)
          setDeudaTotal(respuesta2.Deuda)
        }      
    }
    listaDistribuidoresData();
  }, []);

  useEffect(() => {
    const listaDistribuidoresData = async () => {
      const respuesta = await listaDistribuidores(); // Esta función debe retornar el resultado que necesitas para la validación
      
      const respuesta2 = await listarPedidos(codUsuarioSesion,1,1);
      if(respuesta.SMListaErrores.length ===0){
        setDistribuidores(respuesta.Distribuidores)
      }
      if(respuesta2.SMListaErrores.length ===0){
        setPedidos(respuesta2.Pedidos)
        
      }
    }
    listaDistribuidoresData();
  }, [modalDetalle]);




  const distribuidorSeleccionar = async (valor) => {
    setDistribuidorSeleccionado(valor);
  };

  useEffect(() => {
    if (distribuidorSeleccionado !== null) {
      async function fetchData() {
        const respuesta = await listarAyudantesDistribuidor(distribuidorSeleccionado);
        const respuesta2 = await listarPedidos(distribuidorSeleccionado,1,1);
        if (respuesta.SMListaErrores.length === 0) {
          setAyudantes(respuesta.ayudanteDistribuidores);
          
        }else{
            setAyudantes(null)
        }
        if(respuesta2.SMListaErrores.length ===0){

          setPedidos(respuesta2.Pedidos)
          //console.log(respuesta2)
        }
      }
      fetchData();
    }
  }, [distribuidorSeleccionado]);
  
  const ayudanteDistribuidor= (valor) =>{
    if(valor === ''){
      setayuDistSelec(codUsuarioSesion)
      
    }else{
      setayuDistSelec(valor)

    }
  }
  
  useEffect(() => {
    if (ayuDistSelec !== null) {
      async function fetchData() {
        const respuesta = await listarPedidos(ayuDistSelec,1,1);
        if (respuesta.SMListaErrores.length === 0) {
          
          setPedidos(respuesta.Pedidos)
          //setAyudantes(respuesta.ayudanteDistribuidores);
        }else{
            //setAyudantes(null)
        }
      }
      fetchData();
    }
  }, [ayuDistSelec]);

  const listaDistStyle={
    
    display: "none",
    padding:"0",
    margin:"0",
    //backgroundColor: "green",
    boxSizing: "border-box",
    height: "40px",
    width: "100%",
    gridArea: datos.esComputadora ? '1 / 1 / 2 / 10':'1 / 1 / 2 / 11',
  }
  const cajaTotalStyle={
    padding:"0",
    margin:"0",
    //backgroundColor: "green",
    boxSizing: "border-box",
    height: "40px",
    width: "100%",
    gridArea: datos.esComputadora ? '1 / 1 / 2 / 10':'1 / 1 / 2 / 11',
  }
  const listaAyuDistStyle={
    padding:"0",
    marginBottom:"3vw",
    //backgroundColor: "green",
    boxSizing: "border-box",
    height: "40px",
    width: "100%",
    gridArea: datos.esComputadora ? '1 / 11 / 2 / 21':'1 / 12 / 2 / 21',
    
  }
  const contenedorListaPedidosstyle={
    overflow: "auto",
    gridArea: datos.esComputadora ? '2 / 1 / 21 / 21':'2 / 1 / 21 / 21',
  }
 
  const nomDistStyle={
    display: "block",
    //backgroundColor: "red",
    fontSize: datos.esComputadora ? "1vw":"2vw",
    color: "#363636",
    fontWeight: "bolder",
    marginBottom: "5px",
  }
  const contUsrRecStyle={
    display: "block",
    fontSize: datos.esComputadora ? "1vw":"2vw",
    color: "#868686",
  }
  const usrRecStyle={
    display: "inline-block",
    //backgroundColor: "orange",
    fontSize: "0.9em !important",
    color: "#737373",
    fontWeight: "700",
    marginRight: "5px",
  }
  const contFecSolStyle={
    display: "inline-block",
    //backgroundColor: "purple",
    fontSize: datos.esComputadora ? "1vw":"2vw",
    width: "calc(100%/2)",
    paddingRight: "5px",
    boxSizing: "border-box",    
    textAlign: "center",
    color: "#737373",
  }
  const labFecSolStyle={
    display: "flex",
    //backgroundColor: "#17BDBB",
    height: "2vh",
    justifyContent: "center", /* Centra horizontalmente */
    alignItems: "center",
    textAlign: "center",
    color: "#737373",
    fontWeight: "700",
    marginTop: "5px",
    marginBottom: "-3px",
    fontSize: "0.7em ",

  }
  const contenedorTotales={
    display : "grid",
    gridTemplateRows: "100%",
    gridTemplateColumns: "30% 20% 50%",
    marginTop: "10px",
    marginBottom: "-3px",
  }
  const contTotPeStyle={
    //backgroundColor: "Pink",
    fontSize: "0.6em ",
    gridArea: '1/1/2/2',
    color: "#737373",

  }
  const contTotEnStyle={
    //backgroundColor: "Brown",
    fontSize: "0.8em ",
    gridArea: '1/3/2/4',
    textAlign: "right",
    marginRight: "10%",
    color: "#737373",
  }
  const labelTotPeStyle={
    display: "block",
    color: "#737373",
    fontWeight: "700",
  }
  const labelTotEnStyle={
    display: "block",
    color: "#737373",
    fontWeight: "700",
  }
  const labelFecStyle = {
    fontSize: datos.esComputadora ? '0.9em' : '1.6vh',
    marginLeft: '-10%'
  }
  const cambioFecRecojo = event => {
    //onsole.log(event.target.value)
    setfecRecojo(event.target.value)
    
  }
  useEffect(() => {
    
    if (fecRecojo !== null && fecRecojo !== "") {
      console.log(fecRecojo)
      async function fetchData() {
        const respuesta = await listarPedidos("-1",1,1, fecRecojo);
        if (respuesta.SMListaErrores.length === 0) {
          setDeudaTotal(respuesta.Deuda)
          setPedidos(respuesta.Pedidos)
          //setAyudantes(respuesta.ayudanteDistribuidores);
        }else{
            //setAyudantes(null)
        }
        console.log(respuesta)
      }
      fetchData();
    }
  }, [fecRecojo]);

  const estiloHover = {
    ...(isHovered && {
      cursor: 'pointer',
      //backgroundColor: hexToRGBA('#DCAD2F', 1.0),
      //boxShadow: 'inset 0 0 22px #FFFFFF',
      //...(!datos.esComputadora && { borderBottom: '5px solid #603711' }),
      //...(datos.esComputadora && { borderLeft: '5px solid #603711' })
    })
  }
  const cardPedidostyle={
    //backgroundColor: "#FFF7A9",//"#FCFCFC",
    display: datos.esComputadora ? "inline-block":"",
    borderRadius: "20px",
    margin: datos.esComputadora ? "3vw 0.5vw 0 0.5vw":"3vw 3vw 0 0",
    padding: "10px",
    boxSizing: "border-box",
    width: datos.esComputadora ? "calc(100%/3.15)":"100%",
    border: "1px solid #ccc",
    boxShadow: "0 2px 2px rgba(0, 0, 0, 0.1)",
    ...(isHovered ? estiloHover : ''),

  }
  const manejadorOpcionClick = async (event) =>{
    setIdSeleccionado(event.currentTarget.id)
    setdistSeleccionadoModal(capitalizeWords(event.currentTarget.getAttribute('nombre')))
    setcoddistSeleccionadoModal(event.currentTarget.getAttribute('codigoDistribuidor'))
    setfecSolSeleccionadoModal(event.currentTarget.getAttribute('fechasolicitada'))
    setModalDetalle(true)
  }
  const cerrarModalClick = ()=>{
    setModalDetalle(false)
  }
  const contFecRecStyle = {
    gridArea: datos.esComputadora ? '1 / 17 / 2 / 21' : '1 / 12 / 2 / 21',
    margin: datos.esComputadora ? '0' : '1vw 0px 0px 0px',
    boxSizing: 'border-box',
    width: datos.esComputadora ? '100%' : '100%'
  }
  const modalStyle={
    position: "absolute",
    left: "0px",
    top: "0px",
    width: "100vw",
    height: "100vh",
    backgroundColor: hexToRGBA("#C1C1C1",0.8),
    zIndex: "20", 
    boxSizing: "border-box"

  }
  const contContenidoModalStyle={
    position: "relative",
    
    width: "90vw",
    height: "90vh",
    left: "5vw",
    boxSizing: "border-box",
    top: "5vh",
  }
  const contenidoModalStyle={
    display: 'grid',
    gridTemplateRows: 'repeat(20, 1fr)',
    gridTemplateColumns: 'repeat(20, 1fr)',
    width: "90vw",
    height: "90vh",
    overflow: "auto",
    backgroundColor: "white",
    boxSizing: "border-box",
    borderRadius: "10px",
    padding: "10px"
  }
  const cantTotalStyle={
    display: "inline-block",
    fontWeight: "500",
    fontSize: datos.esComputadora ? "2vw":"2vh",
    textAlign: "left",
  }
  return (
    
    <>
      {modalDetalle ? <div id="modalStyle" style={modalStyle}>
        <div id="contContenidoModalStyle" style={contContenidoModalStyle}>
          <div id="contenidoModalStyle" style={contenidoModalStyle}>
            <DetalleRegistroVentaT esComputadora={datos.esComputadora} idPedido={idSeleccionado} nombreDistribuidor={distSeleccionadoModal} fecSol={fecSolSeleccionadoModal} codigoDistribuidor={coddistSeleccionadoModal} cerrarModalClick={cerrarModalClick}/>
          </div>
        </div>
      </div>: ""
      }
      
      {/* <div id='contenedorListaDist' style={listaDistStyle}>
        <Opciones moderno="Distribuidor" opciones={distribuidores !== null ? distribuidores: []} clave="codUsuario" valor={["Nombre","ApellidoPaterno"]} esComputadora={datos.esComputadora} onClick={distribuidorSeleccionar}/>
      </div> */}
      <div style={cajaTotalStyle}>
        <span style={labelTotEnStyle}>Caja Total {fg.formatear_fecha_ddmmyyyy(fecRecojo ? fecRecojo:obtenerFechaActual())}: </span>
        <span style={cantTotalStyle}>{formatearMonedaSoles(deudaTotal ? deudaTotal: 0)}</span>
      </div>

      {rol ==="SUPER ADMINISTRADOR" && 
      <div id='contenedorFechaRecojo' style={contFecRecStyle}>
      <Input
        style={{
          fontWeight: 'bolder',
          fontSize: datos.esComputadora ? '1.2vw' : '2.5vw',
        }}
        textoStyle={{
          fontWeight: 'bolder',
          fontSize: datos.esComputadora ? '1.2vw' : '2.5vw',
        }}
        id='fechaPedido'
        moderno='Fecha'
        tipo='date'
        autoComplete
        estiloLabel={labelFecStyle}
        onChange={cambioFecRecojo}
        valorInicial={''}
        esComputadora={datos.esComputadora}
      />
      </div>
      }
      <div id= "contenedorListaPedidos" 
      style={contenedorListaPedidosstyle}
      >
        {pedidos!==null && pedidos.map(i=>{
          return <div 
          key={i.codPedido} id={i.codPedido} nombre={i.nombreUsuarioSolicitante ? i.nombreUsuarioSolicitante:'Cliente Varios'} fechasolicitada={i.fechaPedido} codigodistribuidor={i.usuarioSolicitante}
          style={{backgroundColor: i.entregado==='N' ?  "#FFF7A9": "#FCFCFC",...cardPedidostyle}}
          onMouseEnter={event => {
            setIsHovered(true)
            setIdHovered(event.target.id)
          }}
          onMouseLeave={() => {
            setIsHovered(false)
            setIdHovered('')
          }}
          onClick={manejadorOpcionClick}

          >
                    <span style={nomDistStyle}>{capitalizeWords(i.nombreUsuarioSolicitante ? i.nombreUsuarioSolicitante:'Cliente Varios')}</span>
                    <span style={contUsrRecStyle}><label style={usrRecStyle}>Recepcionante:</label>{capitalizeWords(i.nombreUsuarioRecepcionante !==null ? i.nombreUsuarioRecepcionante:"")}</span>
                    <span style={contUsrRecStyle}><label style={usrRecStyle}>Despachador:</label>{capitalizeWords(i.nombreUsuarioEntrega !==null ? i.nombreUsuarioEntrega:"-------")}</span>
                    <span style={contFecSolStyle}><label style={labFecSolStyle}>F. Entrega</label>{i.fechaEntregadaReal === null ? "-------":formatDateTime(i.fechaEntregadaReal)}</span>
                    <span style={contFecSolStyle}><label style={labFecSolStyle}>Total Entregado</label>S/.{i.totalEntrega}</span>

         
                  </div>})}
      </div>
  
    </>
        
        
  )
}

function capitalizeWords(str) {
  let words = str.toLowerCase().split(' ');
  let capitalizedWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return capitalizedWords.join(' ');
}

function formatDateTime(dateTimeStr) {
  // Expresión regular para extraer partes de la fecha y hora UTC
  const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/;
  const match = dateTimeStr.match(regex);

  if (!match) {
    // Manejo de error si el formato de entrada no es válido
    console.error('Formato de fecha y hora no válido:', dateTimeStr);
    return null;
  }

  // Extraer partes de la fecha y hora UTC
  const [, year, month, day, hour, minute] = match;

  // Convertir la hora de formato de 24 horas a formato de 12 horas con AM/PM
  let formattedHour = parseInt(hour, 10);
  const ampm = formattedHour >= 12 ? 'p. m.' : 'a. m.';
  formattedHour = formattedHour % 12;
  formattedHour = formattedHour ? formattedHour : 12; // Hora '0' debería ser '12'

  // Formatear la hora en formato de 12 horas con AM/PM
  formattedHour = `${formattedHour}:${minute} ${ampm}`;

  // Formatear la fecha en dd/mm/yyyy
  const formattedDate = `${day}/${month}/${year}`;

  // Salida final en el formato deseado
  const formattedDateTime = `${formattedDate} ${formattedHour}`;


  return formattedDateTime;
}

function hexToRGBA (hex, opacidad) {
  hex = hex.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacidad})`
}
function obtenerFechaActual() {
  let fechaActual = new Date();
  let año = fechaActual.getFullYear();
  let mes = ('0' + (fechaActual.getMonth() + 1)).slice(-2); // Asegura 2 dígitos
  let dia = ('0' + fechaActual.getDate()).slice(-2); // Asegura 2 dígitos
  return `${año}-${mes}-${dia}`;
}
function formatearMonedaSoles(valor) {
  return valor.toLocaleString('es-PE', {
    style: 'currency',
    currency: 'PEN'
  });
}
export default RegistroVentasTienda
