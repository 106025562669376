export const cabeceraStyle = esComputadora => ({
  gridArea: '1/1/2/21',
  textAlign: 'center',
  fontWeight: '500',
  fontSize: esComputadora ? '2vw' : '3vh',
  padding: '0px',
  lineHeight: '0.9',
  display: 'grid',
  //display: "inline-block",
  fontSize: esComputadora ? '2vw' : '4vw',
  color: '#505050'
  //marginBottom: "10vw"
})
export const contCrearPersonaStyle = esComputadora => ({
  marginTop: '30px',
  display: 'grid',
  gridTemplateColumns: 'repeat(20,1fr)',
  gridTemplateRows: 'repeat(22,1fr)',
  gridArea: esComputadora ? '2/1/21/11' : '2/1/21/21',
  backgroundColor: '#EEEEEE',
  //background: esComputadora ? "radial-gradient(circle at 57.52% 55.27%, #fac44d 0, #f2b53c 50%, #eaa62b 100%)":"",
  border: esComputadora ? '1px solid #ccc' : '1px solid #ccc',
  borderRadius: esComputadora ? '10px' : '10px',
  boxShadow: esComputadora ? '2px 2px 4px rgba(0, 0, 0, 0.2)' : '',
  padding: esComputadora ? '2vw' : '4vw',
  boxSizing: 'border-box',
  width: '100%',
  height: '93%',
  overflow: 'auto'
})
export const nombresStyle = esComputadora => ({
  ...separacionInputs(esComputadora),
  gridArea: '1/1/2/21',
  width: esComputadora ? '100%' : '100%'
})
export const apePaternoStyle = esComputadora => ({
  ...separacionInputs(esComputadora),
  gridArea: '2/1/3/11',
  width: esComputadora ? '100%' : '100%'
  //backgroundColor: "red",
})
export const apeMaternoStyle = esComputadora => ({
  ...separacionInputs(esComputadora),
  gridArea: '2/11/3/21',
  width: esComputadora ? '100%' : '100%'
  //backgroundColor: "red",
})
export const codDocumentoStyle = esComputadora => ({
  ...separacionInputs(esComputadora),
  gridArea: '3/1/4/11',
  width: esComputadora ? '100%' : '100%',
  height: 'auto'
  //backgroundColor: "red",
})
export const numeroDocumentoStyle = esComputadora => ({
  ...separacionInputs(esComputadora),
  gridArea: '3/11/4/21',
  width: esComputadora ? '100%' : '100%'

  //backgroundColor: "red",
})

export const fecNacStyle = (esComputadora,rolSelec) => ({
  ...separacionInputs(esComputadora),
  display: rolSelec === "PRVT01" || rolSelec === "CLTN01" ? "none":"block", 
  gridArea: '4/1/5/11',
  width: esComputadora ? '100%' : '100%'
  //backgroundColor: "red",
})
export const numCelularStyle = esComputadora => ({
  ...separacionInputs(esComputadora),
  gridArea: '4/11/5/21',
  width: esComputadora ? '100%' : '100%',
  height: 'auto'
  //backgroundColor: "red",
})
export const codDepartamentoStyle = (esComputadora,rolSelec) => ({
  ...separacionInputs(esComputadora),
  gridArea: '5/1/6/11',
  display: rolSelec === "PRVT01" || rolSelec === "CLTN01" ? "none":"block", 
  width: esComputadora ? '100%' : '100%',
  height: 'auto'
  //backgroundColor: "red",
})
export const codProvinciaStyle = (esComputadora,rolSelec) => ({
  ...separacionInputs(esComputadora),
  gridArea: '5/11/6/21',
  display: rolSelec === "PRVT01" || rolSelec === "CLTN01" ? "none":"block",
  width: esComputadora ? '100%' : '100%'
  //backgroundColor: "red",
})
export const codDistritoStyle = (esComputadora,rolSelec) => ({
  ...separacionInputs(esComputadora),
  gridArea: '6/1/7/11',
  display: rolSelec === "PRVT01" || rolSelec === "CLTN01" ? "none":"block",
  width: esComputadora ? '100%' : '100%'
  //backgroundColor: "red",
})
export const empStyle = (esComputadora,rolSelec) => ({
  ...separacionInputs(esComputadora),
  gridArea: '4/1/5/11',
  display: rolSelec === 'PRVT01'? 'grid' : 'none',
  width: esComputadora ? '100%' : '100%'
  //backgroundColor: esComputadora ? "blue":"red",
})
export const direccionStyle = (esComputadora,rolSelec) => ({
  ...separacionInputs(esComputadora),
  gridArea: '7/1/8/21',
  display: rolSelec === 'ADIS01' || rolSelec === 'DIST01' || rolSelec === 'PRVT01' || rolSelec === 'CLTN01' ? 'none' : 'grid',
  width: esComputadora ? '100%' : '100%'
  //backgroundColor: esComputadora ? "blue":"red",
})
export const correoElectronicoStyle = esComputadora => ({
  ...separacionInputs(esComputadora),
  gridArea: '8/1/9/21',
  width: esComputadora ? '100%' : '100%'
  //backgroundColor: "red",
})
export const codigoRolStyle = (esComputadora) => ({
  ...separacionInputs(esComputadora),
  gridArea: '9/1/10/21',
  width: esComputadora ? '100%' : '100%'
  //backgroundColor: "red",
})
export const codDistribuidorStyle = (esComputadora, rolSelec) => {
  return {
    display: ['ADIS01','AMST01'].includes(rolSelec) ? 'flex' : 'none',
    gridArea: '10/1/11/21',
    width: esComputadora ? '100%' : '100%'
    //backgroundColor: "red",
  }
}
export const pagoCSS = (esComputadora, rolSelec) => ({
  background:
    'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(173,103,38,1) 0%, rgba(255,255,255,1) 100%)',
  gridArea: rolSelec === 'ADIS01' ? '12/1/13/21' : '11/1/12/21',
  borderTop: '1px #ababab solid',
  borderBottom: '1px #ababab solid',
  display: rolSelec === 'ADIS01' || rolSelec === 'DIST01' || rolSelec === 'PRVT01' || rolSelec === 'CLTN01' ? 'none' : 'flex',
  justifyContent: 'center' /* Centra horizontalmente */,
  alignItems: 'center' /* Centra verticalmente */,
  fontWeight: '700',
  fontFamily: 'Helvetica, sans-serif',
  fontSize: esComputadora ? "1.3vw":"2.8vw",
  padding: esComputadora ? "0.2vw":"1vw",
  margin: esComputadora ? "0.2vw":"1vw",
})
export const pagoValorCSS = (esComputadora, rolSelec) => ({
  display: rolSelec === 'ADIS01' || rolSelec === 'DIST01' || rolSelec === 'PRVT01' || rolSelec === 'CLTN01' ? 'none' : 'grid',
  gridArea: rolSelec === 'ADIS01' ? '13/1/14/21' : '12/1/13/21',
  gridTemplateRows: 'repeat(1,1fr)',
  gridTemplateColumns: 'repeat(3,1fr)'
})
export const montoPagarCSS=(esComputadora)=>({
    ...separacionInputs(esComputadora)
})
export const formaPagoCSS=(esComputadora)=>({
    ...separacionInputs(esComputadora)
})
export const turnoCSS=(esComputadora)=>({
    ...separacionInputs(esComputadora)
})
export const botonEnviarStyle = (esComputadora, rolSelec) => ({
  justifyContent: 'center' /* Centra horizontalmente */,
  alignItems: 'center' /* Centra verticalmente */,
  gridArea: rolSelec === 'ADIS01' ? '15/1/16/21' : '14/1/15/21',
  width: esComputadora ? '100%' : '100%'
  //backgroundColor: "red",
})
export const botonCrearPersonaCSS=(esComputadora)=>({
    ...separacionInputs(esComputadora)
})
export const botonLimpiarDatosCSS=(esComputadora)=>({
    ...separacionInputs(esComputadora)
})
export const modalStyle = esComputadora => ({
  position: 'absolute',
  left:  esComputadora ? '-5vw':'-4vw',
  top:  esComputadora ? '-8vh':'-15vh',
  width: esComputadora ? '100vw':'101vw',
  height:  esComputadora ? '100.3vh':'102.3vh',
  backgroundColor: hexToRGBA('#C1C1C1', 0.8),
  zIndex: '20',
  boxSizing: 'border-box'
})
export const contContenidoModalStyle = esComputadora => ({
  position: 'relative',
  width: esComputadora ? '50vw' : '90vw',
  height: esComputadora ? '50vh' : '30vh',
  left: esComputadora ? '25vw' : '5vw',
  boxSizing: 'border-box',
  top: esComputadora ? '25vh' : '35vh'
})
export const contenidoModalStyle = esComputadora => ({
  display: 'grid',
  gridTemplateRows: 'repeat(20, 1fr)',
  gridTemplateColumns: 'repeat(20, 1fr)',
  width: esComputadora ? '50vw' : '92vw',
  height: esComputadora ? '50vh' : '30vh',
  overflow: 'auto',
  backgroundColor: 'white',
  boxSizing: 'border-box',
  borderRadius: '10px',
  padding: '10px'
})

export const cerrarStyle = esComputadora => ({
  display: 'flex',
  justifyContent:
    'flex-end' /* Alinea los elementos al final del contenedor (derecha) */,
  alignItems: 'flex-start',
  gridArea: '1/18/2/21'
})
export const exitoStyle = esComputadora => ({
  gridArea: '1/1/2/18',
  backgroundColor: 'white',
  display: 'inline',
  textAlign: 'center'
})
export const respuestaStyle = () => ({
  gridArea: '2/1/21/21'
})
export const labelStyle = (esComputadora) => ({
  //display: "inline-block",
  fontFamily: 'Courier New, Courier, monospace', //backgroundColor: "orange",
  fontSize: esComputadora ? '1.2vw':'3vw',
  color: '#737373',
  fontWeight: '700',
  marginRight: '5px'
})
export const contenidoLabelStyle = (esComputadora) => ({
  display: 'grid',
  //display: "inline-block",
  //fontFamily: 'Courier New, Courier, monospace',   //backgroundColor: "orange",
  fontSize: esComputadora ? '1.2vw':'3vw',
  color: '#505050',
  fontWeight: '200',
  marginRight: '5px'
})
export const contLabelStyle = esComputadora => ({
  display: 'grid',
  gridTemplateColumns: '30% 70%',
  gridTemplateRows: '100%',
  padding: esComputadora ? '1vw' : ''
})
function hexToRGBA (hex, opacidad) {
  hex = hex.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacidad})`
}

export const estiloVacio = (esComputadora, vacio) => ({
  //borderColor: usuarioVacio ? 'red':fg.hex_rgb("#ffffff",0),
  border:
    vacio || vacio === '' || vacio === 0
      ? `${esComputadora ? '0.1vw' : '0.2vw'} solid red`
      : `${esComputadora ? '0.1vw' : '0.2vw'} solid #ccc`
})

const separacionInputs = esComputadora => ({
  boxSizing: 'border-box',
  padding: esComputadora ? '0.2vw' : '0.5vw'
})
