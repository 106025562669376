import { useEffect, useState, useContext } from 'react'
import { Contexto } from '../../../index'
import Boton from '../../../../../Elementos/Boton'
import Input from '../../../../../Elementos/Input'
import Opciones from '../../../../../Elementos/Opciones'
import ModalGenerico from './../../../../../Elementos/ModalGenerico'
import * as e from './AEProductoProdCSS'
import borrar from './../../../../../Imagenes/Iconos/borrar.png'
import editar from './../../../../../Imagenes/Iconos/editar.png'

import * as api from './../../../../../Api/Api'
import * as fg from './../../../../../Api/FuncionesGlobales'
import { categoriaAEProdStyle } from '../Productos/AdministrarProductosCSS'

function AEProductProd (props) {
  const { esComputadora, rol, usuariosesion } = useContext(Contexto)
  const [categoriasop, setcategoriasop] = useState(null)

  useEffect(() => {
    console.log(props)
    const ejecucion = async () => {
      const categoriascall = await api.obtenerCategorias('N')
      const prodterminadoscall = await api.obtenerProductos(usuariosesion)
      const datoscall = await api.obtenerDetalleProducto(
        parseInt(props.param.codigoprod),
        null
      )
      console.log(categoriascall)
      console.log(prodterminadoscall)
      console.log(datoscall)
      if (categoriascall && prodterminadoscall && datoscall) {
        if (
          categoriascall.SMListaErrores.length === 0 &&
          prodterminadoscall.SMListaErrores.length === 0 &&
          datoscall.SMListaErrores.length === 0
        ) {
          console.log(categoriascall)
          setcategoriasop(categoriascall.categorias)
          console.log(prodterminadoscall)
          console.log(datoscall)
        } else {
          alert(
            'Ocurrió un error al cargar la información, comunicate con soporte técnico'
          )
        }
      }
    }
    ejecucion()
  }, [])
  return (
    <>
      <e.Contenido id="algonousual" $escomputadora={esComputadora}>
        <div className='nomprodparam'>{props.param.nombreprod}</div>
        <div className='categoriai'>
          <Opciones
            valorInicial={''}
            id='listacategoriasop'
            formPersona='entra'
            moderno='Categoria'
            opciones={categoriasop !== null ? categoriasop : []}
            clave='codCategoria'
            valor={['descCategoria']}
            esComputadora={esComputadora}
            onClick={e => {
              //setmaestroselec(e)
            }}
          />
        </div>
        <div className='nombreprodi'>
          <Input
            id='nombreproductoin'
            moderno={'Nombre'}
            tipo='text'
            onChange={e => {
              
            }}
            valorInicial={''}
            esComputadora={esComputadora}
            
          />
        </div>
      </e.Contenido>
    </>
  )
}

export default AEProductProd
