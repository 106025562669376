import styled, { css } from 'styled-components'

export const Contenido = styled.div`

    //background-color: green;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: repeat(20,1fr);
    grid-template-columns: repeat(20,1fr);

    .nomprodparam{
        grid-area: 1/1/1/21;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: ${props => props.$escomputadora ? '':''}vw;
    }
    .categoriai{
        grid-area: 2/1/3/11;
        padding: 1vw;
    }
    .nombreprodi{
        grid-area: 2/11/3/21;
        padding: 1vw;
    }  
`