export const contenedor = esComputadora => ({
  gridArea: '1/1/21/21',
  display: 'grid',
  gridTemplateRows: 'repeat(20,1fr)',
  gridTemplateColumns: 'repeat(20,1fr)',
  borderRadius: esComputadora ? '':'',
  boxShadow: '0.1vw 0.1vw 1vw #aaaaaa',
})

export const titulo = esComputadora => ({
  gridArea: '1/1/2/21',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#AD6726',
  fontSize: esComputadora ? '2vw' : '4vw',
  color: "white",
  fontWeight: "bolder",
  borderRadius: esComputadora ? '0.5vw 0.5vw 0 0':'1vw 1vw 0 0'
})

export const centro = esComputadora => ({
  gridArea: '2/1/19/21',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  //backgroundColor: 'skyblue',
  border: "0.1vw solid #cccccc",
  padding: esComputadora ? '1.5vw' : '2vw',
  boxSizing: "border-box"
})

export const listado = esComputadora => ({
  //gridArea: "2/1/19/21",
  padding: "0",
  margin: "0",
  display: 'block',
  justifyContent: 'center',
  alignItems: 'center',
  //backgroundColor: 'orange',
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',
  position: 'relative',
  overflow: "auto",
})

export const cabeceraListado = esComputadora => ({
  //gridArea: "2/1/19/21",
  display: 'grid',
  textAlign: 'center',
  gridTemplateColumns: esComputadora ? '30% 35% 15% 20%':'30% 36% 12% 20%',
  gridTemplateRows: '100%',
  top: '0',
  boxSizing: 'border-box',
  position: 'relative',
  fontSize: esComputadora ? '1vw':'2.7vw',
  height: "5%",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: "bolder",
  marginBottom: "0.5vw"
})
export const elemCabListado = (esComputadora)=>({
    borderRight:"0.1vw solid black",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: '#eeeeee',
})
export const elemCuerpoListado = (esComputadora)=>({
    ...elemCabListado(esComputadora),
    backgroundColor: "white",
    border: "none",
    paddingBottom:"0.5vw",
    borderBottom: "0.1vw solid #cccccc"
})
export const elemCuerpoHoverListado = (esComputadora)=>({
    backgroundColor: "#cccccc",
})
export const elemCuerpoOpListado = (esComputadora)=>({
    ...elemCuerpoListado(esComputadora),
    display: "flex",
    gap: "1% 15%"
})
export const inactivoElem = (esComputadora)=>({
  backgroundColor: "#ffd9d9"
})
export const cuerpoListado = (esComputadora,hover,leave) => ({
  display: 'grid',
  textAlign: 'center',
  gridTemplateColumns: esComputadora ? '30% 35% 15% 20%':'30% 36% 12% 20%',
  gridTemplateRows: '100%',
  boxSizing: 'border-box',
  position: 'relative',
  fontSize: esComputadora ? '1vw':'2.3vw',
  justifyContent: "center",
  alignItems: "center",
  margin:"1vw 0 1vw 0",
})

export const opciones = esComputadora => ({
  gridArea: '19/1/21/21',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#AD672633',
  padding: '1vh',
  gap: '1% 1%',
  boxSizing: 'border-box',
  flexDirection: 'row',
  flexWrap: 'wrap',
  overflow: 'auto',
  order: ''
})
export const img=(esComputadora)=>({
    width: esComputadora ? "10%":"20%",
    height: "auto",
    cursor: "pointer"
})