import React, { useState, useEffect } from 'react'
import {
  actualizarEntrega,
  listarAyudantesDistribuidor,
  solicitarPedido,
  obtenerCookie,
  listarPersonal,
  pedidoTienda
} from './../../../../Api/Api'
import Opciones from './../../../../Elementos/Opciones'
import Input from './../../../../Elementos/Input'
import Boton from './../../../../Elementos/Boton'
import * as fg from './../../../../Api/FuncionesGlobales'
import './VentaTienda.css'

function VentaTienda (datos) {
  const [isFocused, setIsFocused] = useState(false)
  const [personal, setPersonal] = useState(null)
  const [distribuidorSeleccionado, setDistribuidorSeleccionado] = useState(null)
  const [ayudantes, setAyudantes] = useState(null)
  const [ayuDistSelec, setayuDistSelec] = useState(null)
  const [fecRecojo, setfecRecojo] = useState(null)
  const [listaproductos, setlistaproductos] = useState(null)
  const [totalentregado, settotalentregado] = useState(0.0)
  const [listaproductosoriginal, setlistaproductosoriginal] = useState([])
  const [codPedido, setCodPedido] = useState(null)
  const [subTotales, setSubTotales] = useState([])
  const [estaProcesando, setestaProcesando] = useState(false)
  const [rol, setRol] = useState(
    obtenerCookie('descRol') !== null
      ? obtenerCookie('descRol').toUpperCase().trim()
      : ''
  )
  const [codUsuarioSesion, setcodUsuarioSesion] = useState(
    obtenerCookie('codUsuario') !== null
      ? obtenerCookie('codUsuario').toUpperCase().trim()
      : ''
  )
  const [fproducto, setfproducto] = useState('')
  const [fcategoria, setfcategoria] = useState('')
  const [fprecio, setfprecio] = useState('')
  const [actualizo, setactualizo] = useState(false)
  useEffect(() => {
    const listaDistribuidoresData = async () => {
      const respuesta = await listarPersonal() // Esta función debe retornar el resultado que necesitas para la validación
      const respuesta2 = await pedidoTienda('')
      if (respuesta) {
        if (respuesta.SMListaErrores.length === 0) {
          setPersonal(respuesta.Empleados)
        }
      }
      if (respuesta2) {
        if (respuesta2.SMListaErrores.length === 0) {
          setlistaproductos(respuesta2.ProductosPedidosPorCategoria)
          settotalentregado(respuesta2.totalEntrega)
          setDistribuidorSeleccionado('')
          setCodPedido(respuesta2.codPedido)
          setlistaproductosoriginal(respuesta2.ProductosPedidosPorCategoria)
        }
      }
    }
    listaDistribuidoresData()
  }, [])

  const distribuidorSeleccionar = async valor => {
    setDistribuidorSeleccionado(valor)
  }

  useEffect(() => {
    if (distribuidorSeleccionado !== null) {
      async function fetchData () {
        const respuesta = await listarAyudantesDistribuidor(
          distribuidorSeleccionado
        )
        const respuesta2 = await pedidoTienda(distribuidorSeleccionado)
        if (respuesta) {
          if (respuesta.SMListaErrores.length === 0) {
            setAyudantes(respuesta.ayudanteDistribuidores)
          } else {
            setAyudantes(null)
          }
        }
        if (respuesta2) {
          if (respuesta2.SMListaErrores.length === 0) {
            setlistaproductos(respuesta2.ProductosPedidosPorCategoria)
            settotalentregado(respuesta2.totalEntrega)
            setlistaproductosoriginal(respuesta2.ProductosPedidosPorCategoria)
            setCodPedido(respuesta2.codPedido)
          } else {
            setlistaproductos(null)
            settotalentregado(0.0)
            setlistaproductosoriginal(null)
            setayuDistSelec(null)
          }
        }
      }
      fetchData()
    } else {
      setayuDistSelec(null)
    }
  }, [distribuidorSeleccionado])

  const ayudanteDistribuidor = async valor => {
    setayuDistSelec(valor)
  }

  useEffect(() => {
    if (ayuDistSelec !== null) {
      async function fetchData () {
        const respuesta = await pedidoTienda(ayuDistSelec)
        if (respuesta) {
          if (respuesta.SMListaErrores.length === 0) {
            setlistaproductos(respuesta.ProductosPedidosPorCategoria)
            settotalentregado(respuesta.totalEntrega)
            setlistaproductosoriginal(respuesta.ProductosPedidosPorCategoria)
            //const subTotalesTemp = respuesta.pedidos.
            //setSubTotales([...subTotales,prod.subtotalSolicitada])
            setCodPedido(respuesta.codPedido)
            //setAyudantes(respuesta.ayudanteDistribuidores);
          } else {
            const respuesta2 = await pedidoTienda(distribuidorSeleccionado)
            if (respuesta2) {
              if (respuesta2.SMListaErrores.length === 0) {
                setlistaproductos(respuesta2.ProductosPedidosPorCategoria)
                settotalentregado(respuesta2.totalEntrega)
                setlistaproductosoriginal(
                  respuesta2.ProductosPedidosPorCategoria
                )
                setCodPedido(respuesta2.codPedido)
                setayuDistSelec(null)
              } else {
                setlistaproductos(null)
                settotalentregado(null)
                setlistaproductosoriginal(null)
              }
            }
          }
        }
      }
      fetchData()
    }
  }, [ayuDistSelec])

  const listaDistStyle = {
    display: rol === 'DESPACHADOR' ? 'none' : '',
    padding: '0',
    margin: '0',
    //backgroundColor: "green",
    boxSizing: 'border-box',
    height: '40px',
    width: '100%',
    gridArea: datos.esComputadora ? '1 / 1 / 2 / 5' : '1 / 1 / 2 / 11'
  }
  const listaAyuDistStyle = {
    boxSizing: 'border-box',
    fontSize: datos.esComputadora ? '1vw' : '2vw',
    width: '100%',
    gridArea: datos.esComputadora ? '1 / 6 / 2 / 21' : '1 / 12 / 2 / 21'
  }
  const filtros = {
    gridArea: datos.esComputadora ? '2/1/3/21' : '2 / 1 / 3 / 21',
    display: 'grid',
    gridTemplateColumns: datos.esComputadora
      ? '40% 26% 26% 8%'
      : '40% 23% 23% 14%',
    gridTemplateRows: '100%',
    backgroundColor: 'rgb(250, 250, 250)',
    boxShadow: datos.esComputadora
      ? 'inset rgb(129, 129, 129) 0px 0px 0.3vw'
      : 'inset rgb(129, 129, 129) 0px 0px 1.0vw',
    padding: '0.5vw',
    boxSizing: 'border-box'
  }
  const contenedorlistaproductos = {
    gridArea: datos.esComputadora ? '3 / 1 / 19 / 21' : '3 / 1 / 19 / 21',
    backgroundColor: '#F4F4F4',
    overflow: 'auto',
    boxSizing: 'border-box',
    border: '0.1vw solid #ccc',
    padding: '0 0 0 1vw',
    //marginTop: datos.esComputadora ? '-1vw' : '1vw',
    borderRadius: datos.esComputadora ? '0.2vw' : '0.5vw'
  }
  const contenedorTotalesStyle = {
    display: 'grid',
    gridTemplateColumns: '50% repeat(4,1fr)',
    gridTemplateRows: '100%',
    gridArea: datos.esComputadora ? '19 / 1 / 21 / 21' : '19 / 1 / 21 / 21',
    backgroundColor: '#F4F4F4',
    boxSizing: 'border-box',
    marginTop: datos.esComputadora ? '0.5vw' : '1vw',
    boxSizing: 'border-box',
    border: '0.2vw solid #ccc',
    borderRadius: '0.2vw',
    textAlign: 'center',
    padding: datos.estaProcesando ? '0.25vw' : '0.5vw',
    border: '0.1vw solid #cccccc'
  }

  const cardPedidostyle = {
    backgroundColor: 'white', //"#FCFCFC",
    display: 'inline-block',
    //clear: "both",
    borderRadius: '1vw',
    margin: '1vw 1vw 0 0',
    //padding: "10px",
    overflow: datos.esComputadora ? 'auto' : 'none',
    height: datos.esComputadora ? '15vw' : '',
    boxSizing: 'border-box',
    width: datos.esComputadora ? 'calc(100%/3.12)' : '100%',
    border: '0.1vw solid #ccc',
    boxShadow: '0 0.2vw 0.2vw rgba(0, 0, 0, 0.1)'
  }
  const nomCatProdStyle = {
    display: 'block',
    backgroundColor: '#AD6726',
    color: 'white',
    fontSize: datos.esComputadora ? '1vw' : '1.7vh',
    //color: "#363636",
    fontWeight: 'bolder',
    //marginBottom: "5px",
    textAlign: 'Center',
    borderRadius: '10px 10px 0px 0px',
    padding: '5px'
  }
  const contProdStyle = {
    padding: '0 10px 5px 10px ',
    backgroundColor: '#FFFFFF',
    borderRadius: '0px 0px 18px 18px'
  }
  const contCabProductoStyle = {
    display: 'grid',
    gridTemplateColumns: '30% repeat(3,1fr)', //"repeat(5,1fr)",
    gridTemplateRows: '100%',
    fontWeight: '800',

    fontSize: datos.esComputadora ? '1vw' : '2.2vw',
    color: '#868686',
    textAlign: 'center'
  }
  const contProductoStyle = {
    display: 'grid',
    gridTemplateColumns: '30% repeat(3,1fr)',
    gridTemplateRows: '100%',
    marginTop: datos.esComputadora ? '0.25vw' : '0.5vw',
    fontSize: datos.esComputadora ? '0.8vw' : '2.5vw',
    color: '#868686',
    marginTop: datos.esComputadora ? '1vw':"2vw"
  }

  const descProdStyle = {
    gridArea: '1/1/1/2',
    display: 'flex',
    paddingRight: '5px',
    boxSizing: 'border-box',
    textAlign: 'left',
    //justifySelf: "center",
    alignItems: 'center',
    color: '#737373',
    whiteSpace: 'pre-wrap',
    overflow: 'auto',
    fontWeight: '500',
    overflow: 'auto',
  }
  const precProdStyle = {
    display: 'flex',
    paddingRight: '5px',
    boxSizing: 'border-box',
    textAlign: 'center',
    justifySelf: 'center',
    alignItems: 'center',
    color: '#737373'
  }
  const ultPedProdStyle = {
    display: 'flex',
    paddingRight: '5px',
    boxSizing: 'border-box',
    textAlign: 'center',
    justifySelf: 'center',
    alignItems: 'center',
    color: '#737373'
  }
  const pedProdStyle = {
    display: 'flex',
    paddingRight: '5px',
    boxSizing: 'border-box',
    textAlign: 'center',
    justifySelf: 'center',
    alignItems: 'center',
    color: '#737373'
  }
  const subToPedStyle = {
    display: 'flex',
    paddingRight: '5px',
    boxSizing: 'border-box',
    textAlign: 'center',
    justifySelf: 'center',
    alignItems: 'center',
    color: '#737373',
    fontWeight: '900'
  }
  const guardarPedidoStyle = {
    //backgroundColor: "Brown",
    gridArea: datos.esComputadora ? '1 / 4 / 2 / 5' : '1 / 4 / 2 / 6',
    fontSize: datos.esComputadora ? '1vw' : '2vw',
    display: 'flex',
    justfyContent: 'center',
    alignItems: 'center',
    color: '#737373'
  }
  const labelTotPeStyle = {
    display: 'block',
    color: '#737373',
    fontWeight: '700'
  }
  const labelTotEnStyle = {
    display: 'block',
    color: '#737373',
    fontWeight: '700'
  }
  const contFecPedStyle = {
    gridArea: datos.esComputadora ? '1 / 1 / 2 / 5' : '1 / 1 / 2 / 11',
    boxSizing: 'border-box',
    width: datos.esComputadora ? '100%' : '100%'
  }
  const contFecRecStyle = {
    display: rol === 'DESPACHADOR' ? 'none' : '',
    gridArea: datos.esComputadora ? '1 / 17 / 2 / 21' : '2 / 12 / 2 / 21',
    boxSizing: 'border-box',
    width: datos.esComputadora ? '100%' : '100%'
  }
  const inputFecStyle = {
    height: '1px',
    padding: '15px 10px 5px',
    height: '100%',
    marginTop: '-5px',
    marginLeft: '-10%',
    width: '90%',
    borderRadius: '10px',
    border: '1px solid #ccc',
    boxShadow: '0 1px 1px rgba(0, 0, 0, 0.1)'
  }
  const labelFecStyle = {
    fontSize: datos.esComputadora ? '0.9em' : '1.6vh',
    marginLeft: '-10%'
  }
  const pedido = {
    color: 'red',
    padding: '0',
    margin: '0',
    width: '100%',
    fontSize: datos.esComputadora ? '1vw' : '2vw',
    textAlign: 'center',
    border: datos.esComputadora
      ? '0.1vw solid rgb(151, 151, 151)'
      : '0.2vw solid rgb(151, 151, 151)',
    borderRadius: datos.esComputadora ? '0.4vw' : '0.6vw',
    height: datos.esComputadora ? '1.6vw' : '3.5vw'
  }
  const totalStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gridArea: datos.esComputadora ? '1 / 1 / 2 / 2' : '1 / 1 / 2 / 2',
    marginRight: '10px',
    fontSize: datos.esComputadora ? '1.1vw' : '3vw',
    fontWeight: '500',
    textAlign: 'right'
  }
  const cantTotalStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gridArea: datos.esComputadora ? '1 / 2 / 2 / 3' : '1 / 2 / 2 / 3',
    fontWeight: '500',
    fontSize: datos.esComputadora ? '1.5vw' : '3.5vw',
    textAlign: 'left'
  }
  const cambioFecPedido = event => {
    event.target.value = obtenerFechaActual()
  }

  const cambioCantPedido = async event => {
    const respuesta = await actualizarEntrega(
      ayuDistSelec ? ayuDistSelec : distribuidorSeleccionado,
      parseInt(event.target.id),
      parseInt(codPedido),
      isNaN(parseInt(event.target.value)) ? 0 : parseInt(event.target.value)
    )
    if (respuesta) {
      if (respuesta.SMListaErrores.length === 0) {
        const subTotaltemp = document.getElementById(
          respuesta.codProducto + '' + respuesta.codPedido
        )
        const totalTemp = document.getElementById('cantTotal')

        if (subTotaltemp && totalTemp) {
          subTotaltemp.textContent = formatearMonedaSoles(
            respuesta.subtotalEntrega
          )
          totalTemp.textContent = formatearMonedaSoles(respuesta.totalEntrega)

          const actualizarlistaoriginal = (listaoriginal, respuesta, event) => {
            // Recorrer listaoriginal y actualizar productos según el criterio fproducto
            listaoriginal.forEach(e => {
              e.productos.forEach(m => {
                if (
                  /*m.descProducto
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toUpperCase()
                    .includes(
                      respuesta.producto
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .toUpperCase()
                    )*/
                   m.codProducto === respuesta.codProducto
                ) {
                  m.canEntregada = parseInt(event.target.value)
                  m.subtotalEntregada = respuesta.subtotalEntrega
                }
              })
            })

            return listaoriginal
          }

          // Llamada a la función
          const listaoriginalActualizado = actualizarlistaoriginal(
            listaproductosoriginal,
            respuesta,
            event
          )

          setlistaproductosoriginal(listaoriginalActualizado)
        }
      } else {
        alert(
          '¡Advertencia! Se generó un error registrando el producto, SE RECARGARÁ LA PÁGINA'
        )
        window.location.reload()
      }
    } else {
      alert(
        '¡Advertencia! Se generó un error generando la venta, SE RECARGARÁ LA PÁGINA'
      )
      window.location.reload()
    }
  }
  const cambioFecRecojo = event => {
    setfecRecojo(event.target.value)
  }
  const registrarVentaClick = async event => {
    setestaProcesando(true)
    try {
      const respuesta = await solicitarPedido(
        ayuDistSelec ? ayuDistSelec : distribuidorSeleccionado,
        codPedido,
        obtenerFechaActual() + 'T' + obtenerHoraActual(),
        2
      )
      if (respuesta) {
        if (respuesta.SMListaErrores.length === 0) {
          setestaProcesando(false)
          window.location.reload()
        } else {
          setestaProcesando(false)
        }
      }
    } catch (error) {
      setestaProcesando(false)
    }
  }

  useEffect(() => {
    let temp = []

    const resultado = listaproductosoriginal
      .filter(e =>
        fcategoria === ''
          ? true
          : e.descCategoria
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toUpperCase()
              .includes(fcategoria)
      ) // Filtro a nivel de productos original
      .map(e => ({
        ...e,
        productos: e.productos.filter(
          m =>
            (fproducto === ''
              ? true
              : m.descProducto
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .toUpperCase()
                  .includes(fproducto)) &&
            (fprecio === ''
              ? true
              : parseFloat(m.subtotalEntregada !== "" ? m.subtotalEntregada : 0) >= parseFloat(fprecio !== "" ? fprecio : 0))
        )
      }))
      .filter(e => e.productos.length > 0)
    setlistaproductos(resultado)
  }, [fproducto, fcategoria, fprecio])

  return (
    <>
      <div id='contenedorFechaPedido' style={contFecPedStyle}>
        <Input
          style={{
            fontWeight: 'bolder',
            fontSize: datos.esComputadora ? '1.2vw' : '2.5vw'
          }}
          textoStyle={{
            fontWeight: 'bolder',
            fontSize: datos.esComputadora ? '1.2vw' : '2.5vw'
          }}
          id='fechaPedido'
          moderno='Fecha de Venta'
          tipo='date'
          autoComplete
          onChange={cambioFecPedido}
          valorInicial={obtenerFechaActual()}
          deshabilitado
          esComputadora={datos.esComputadora}
        />
      </div>
      <div id='contenedorFechaRecojo' style={contFecRecStyle}>
        <Input
          style={{}}
          id='fechaPedido'
          moderno='Fecha de Recojo'
          tipo='date'
          autoComplete
          estilos={inputFecStyle}
          estiloLabel={labelFecStyle}
          onChange={cambioFecRecojo}
          valorInicial={''}
        />
      </div>
      <div id='contenedorListaDist' style={listaDistStyle}>
        <Opciones
          moderno='Distribuidor'
          opciones={personal !== null ? personal : []}
          clave='codUsuario'
          valor={['Nombre', 'ApellidoPaterno']}
          esComputadora={datos.esComputadora}
          onClick={distribuidorSeleccionar}
        />
      </div>
      <div id='contenedorListaAyuDist' style={listaAyuDistStyle}>
        <Opciones
          moderno='Personal'
          opciones={personal !== null ? personal : []}
          clave='codUsuario'
          valor={['Nombre', 'ApellidoPaterno']}
          esComputadora={datos.esComputadora}
          onClick={ayudanteDistribuidor}
        />
      </div>
      <div style={filtros}>
        <div style={{ padding: '0.1vw' }}>
          <Input
            style={{}}
            textoStyle={{}}
            id='fproducto'
            moderno={'Producto'}
            tipo='text'
            onChange={e => {
              setfproducto(e.currentTarget.value.toUpperCase().trim())
              //filtrosNomApe(e.currentTarget.value)
            }}
            valorInicial={''}
            esComputadora={datos.esComputadora}
            limpiar={fproducto === '' ? true : false}
          />
        </div>
        <div style={{ padding: '0.1vw' }}>
          <Input
            style={{}}
            textoStyle={{}}
            id='fcategoria'
            moderno={'Categoría'}
            tipo='text'
            onChange={e => {
              setfcategoria(e.currentTarget.value.toUpperCase().trim())
              //filtrosNomApe(e.currentTarget.value)
            }}
            valorInicial={''}
            esComputadora={datos.esComputadora}
            limpiar={fcategoria === '' ? true : false}
          />
        </div>
        <div style={{ padding: '0.1vw' }}>
          <Input
            style={{}}
            textoStyle={{}}
            id='fprecio'
            moderno={'Sub Total ≥'}
            tipo='number'
            onChange={e => {
              setfprecio(e.currentTarget.value.toUpperCase().trim())
              //filtrosNomApe(e.currentTarget.value)
            }}
            valorInicial={''}
            esComputadora={datos.esComputadora}
            limpiar={fprecio === '' ? true : false}
          />
        </div>
        <div
          style={{
            padding: '0.1vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Boton
            style={{
              backgroundColor: '#E12121',
              borderRadius: datos.esComputadora ? '0.5vw' : '1vw',
              padding: datos.esComputadora ? '0.8vw' : '1vw'
            }}
            textoStyle={{
              color: 'white',
              width: '100%',
              fontSize: datos.esComputadora ? '1vw' : '2.3vw',
              padding: '0'
            }}
            texto='Limpiar Filtros'
            tipo='submit'
            estaProcesando={false}
            onClick={event => {
              event.preventDefault()
              //setactualizo(e => !e)
              setfproducto('')
              setfcategoria('')
              setfprecio('')
            }}
          />
        </div>
      </div>
      <div id='contenedorlistaproductos' style={contenedorlistaproductos}>
        {listaproductos !== null &&
          listaproductos.map(pppc => {
            return (
              <div
                key={pppc.codCategoria}
                id={pppc.descCategoria}
                style={cardPedidostyle}
              >
                <div style={nomCatProdStyle}> {pppc.descCategoria} </div>
                <div style={contProdStyle}>
                  <div style={contCabProductoStyle}>
                    <div>Producto</div>
                    <div>Precio x Unidad</div>
                    <div>Pedido</div>
                    <div>Sub Total</div>
                  </div>
                  {pppc.productos.map(prod => {
                    return (
                      <div
                        className='listaProductos'
                        id={prod.codProducto}
                        key={prod.codProducto}
                        style={contProductoStyle}
                      >
                        <div style={descProdStyle}>
                          {prod.descProducto
                            ? fg.capitalizar_palabra(prod.descProducto)
                            : ''}
                        </div>
                        <div style={precProdStyle}>
                          {formatearMonedaSoles(prod.precioUnitario)}
                        </div>
                        <div style={pedProdStyle}>
                          <Input
                            style={{
                              ...pedido
                            }}
                            antiguo='' //{prod.canSolicitada !==0 ? prod.canSolicitada:null}
                            tipo='number'
                            onBlur={cambioCantPedido}
                            onChange={event => {
                              if (event.currentTarget.value === '')
                                cambioCantPedido(event)
                            }}
                            id={prod.codProducto}
                            valorInicial={
                              prod.canEntregada !== 0 ? prod.canEntregada : ''
                            }
                            estadoCambio={codPedido !== 0 ? codPedido : ''}
                          />
                        </div>
                        <div
                          id={prod.codProducto + '' + codPedido}
                          style={subToPedStyle}
                        >
                          {formatearMonedaSoles(prod.subtotalEntregada)}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
      </div>
      <div id='contenedorTotales' style={contenedorTotalesStyle}>
        <div id='total' style={totalStyle}>
          TOTAL:
        </div>
        <div id='cantTotal' style={cantTotalStyle}>
          {listaproductos !== null && formatearMonedaSoles(totalentregado)}
        </div>
        <div id='guardarPedido' style={guardarPedidoStyle}>
          <Boton
            style={{
              backgroundColor: '#2D8B0B',
              borderRadius: datos.esComputadora ? '0.5vw' : '1vw',
              padding: datos.esComputadora ? '0.5vw' : '1vw'
            }}
            textoStyle={{
              color: 'white',
              width: '100%',
              fontSize: datos.esComputadora ? '1vw' : '2.5vw'
            }}
            texto='Registrar Venta'
            tipo='submit'
            tonalidad='20'
            estaProcesando={estaProcesando}
            onClick={registrarVentaClick}
          />
        </div>
      </div>
    </>
  )
}

function capitalizeWords (str) {
  let words = str.toLowerCase().split(' ')
  let capitalizedWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1)
  })
  return capitalizedWords.join(' ')
}

function formatDateTime (dateTimeStr) {
  const dateObj = new Date(dateTimeStr) // Crear un objeto Date desde la cadena

  const day = String(dateObj.getDate()).padStart(2, '0')
  const month = String(dateObj.getMonth() + 1).padStart(2, '0')
  const year = String(dateObj.getFullYear()).slice(-2) // Obtener los últimos dos dígitos del año

  const formattedDate = `${day}/${month}/${year}`
  let hours = dateObj.getHours()
  const minutes = String(dateObj.getMinutes()).padStart(2, '0')
  const ampm = hours >= 12 ? 'pm' : 'am'

  hours = hours % 12
  hours = hours ? hours : 12 // '0' debería ser '12' en el formato de 12 horas

  const formattedTime = `${hours}:${minutes} ${ampm}`
  //${formattedTime}
  return `${formattedDate}`
}
function obtenerHoraActual () {
  const ahora = new Date()
  const horas = ahora.getHours().toString().padStart(2, '0')
  const minutos = ahora.getMinutes().toString().padStart(2, '0')
  const segundos = ahora.getSeconds().toString().padStart(2, '0')
  return `${horas}:${minutos}:${segundos}`
}
function obtenerFechaActual () {
  let fechaActual = new Date()
  let año = fechaActual.getFullYear()
  let mes = ('0' + (fechaActual.getMonth() + 1)).slice(-2) // Asegura 2 dígitos
  let dia = ('0' + fechaActual.getDate()).slice(-2) // Asegura 2 dígitos
  return `${año}-${mes}-${dia}`
}
function formatearMonedaSoles (valor) {
  return valor.toLocaleString('es-PE', {
    style: 'currency',
    currency: 'PEN'
  })
}
function hexToRGBA (hex, opacidad) {
  hex = hex.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacidad})`
}
export default VentaTienda
