import { useEffect, useState, useContext } from 'react'
import { Contexto } from '../../../index'
import Boton from '../../../../../Elementos/Boton'
import Input from '../../../../../Elementos/Input'
import Opciones from '../../../../../Elementos/Opciones'
import ModalGenerico from './../../../../../Elementos/ModalGenerico'
import * as e from './ProductosProdCSS'
import borrar from './../../../../../Imagenes/Iconos/borrar.png'
import editar from './../../../../../Imagenes/Iconos/editar.png'
import AEProductProd from './AEProductoProd'
import REMaestro from './REMaestro'
import * as api from './../../../../../Api/Api'
import * as fg from './../../../../../Api/FuncionesGlobales'

function ProductosProd (props) {
  const { esComputadora } = useContext(Contexto)
  const [primeracarga, setprimeracarga] = useState(true)
  const [maestros, setmaestros] = useState(null)
  const [fproducto, setfproducto] = useState('')
  const [fcategoria, setfcategoria] = useState('')
  const [fprecio, setfprecio] = useState('')
  const [productos, setproductos] = useState(null)
  const [productosOriginal, setproductosoriginal] = useState([])
  const [maestroselec, setmaestroselec] = useState('')
  const [vermodal, setvermodal] = useState(false)
  const [registroselec, setregistroselec] = useState('')
  const [opclick, setopclick] = useState('')
  const [dispotivo, setdispositivo] = useState([])
  const [param, setparam] = useState({
    nombreprod: null,
    codigoprod: null,
    nombrema: null,
    maestrorel: null,
    nombreprodrel: null,
    productorel: null
  })
  const [opmodal, setopmodal] = useState({
    epp: { wc: 50, hc: 20, wm: 80, hm: 80, t: 'Eliminar Producto Producción' },
    mpp: { wc: 80, hc: 80, wm: 80, hm: 80, t: 'Editar Producto Producción' },
    emr: { wc: 50, hc: 20, wm: 50, hm: 20, t: 'Eliminar Relación con Maestro' },
    mmr: { wc: 80, hc: 80, wm: 50, hm: 20, t: 'Editar Maestro Relacionado' },
    epr: {
      wc: 80,
      hc: 80,
      wm: 50,
      hm: 20,
      t: 'Eliminar Relación Con Producto'
    },
    mpr: { wc: 80, hc: 80, wm: 50, hm: 20, t: 'Editar Producto Relacionado' },
    rmp: { wc: 80, hc: 80, wm: 90, hm: 90, t: 'Relacionar Maestro' },
    rpp: { wc: 80, hc: 80, wm: 90, hm: 90, t: 'Relacionar Producto Terminado' }
  })

  useEffect(() => {
    const ejecucion = async () => {
      const maestroscall = await api.listarMaestros(null, 'S')
      const lprodmacall = await api.listaProductoMaestro(null, null, 'S', 'S')
      
      if (maestroscall && lprodmacall) {
        setmaestros(maestroscall.maestro)
        setproductos(lprodmacall.productos)
        setproductosoriginal(lprodmacall.productos)
        console.log(lprodmacall.productos)
      }
      setprimeracarga(false)
    }
    ejecucion()
  }, [])

  useEffect(() => {
    if (!primeracarga) {
      const ejecucion = async () => {
        const lprodmacall = await api.listaProductoMaestro(
          maestroselec === '' ? null : maestroselec,
          null,
          'S',
          'S'
        )
        console.log(lprodmacall)
        if (lprodmacall) {
          setproductos(lprodmacall.productos)
          setproductosoriginal(lprodmacall.productos)
          console.log(lprodmacall.productos)
        }
      }
      ejecucion()
    }
  }, [maestroselec])

  useEffect(() => {
    if (!primeracarga) {
      const resultado = productosOriginal
        .filter(e =>
          fcategoria === ''
            ? true
            : e.categoria.descripcion
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toUpperCase()
                .includes(fcategoria)
        ) // Filtro a nivel de stockoriginal
        .map(e => ({
          ...e,
          productos: e.productos.filter(
            m =>
              (fproducto === ''
                ? true
                : m.descripcion
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toUpperCase()
                    .includes(fproducto)) &&
              (fprecio === ''
                ? true
                : parseFloat(m.precio) >= parseFloat(fprecio))
          )
        }))
        .filter(e => e.productos.length > 0)
      //console.log(resultado)
      setproductos(resultado)
    }
  }, [fproducto, fcategoria, fprecio])

  useEffect(() => {
    if (!primeracarga && opclick !== '') {
      setdispositivo(esComputadora ? ['wc', 'hc'] : ['wm', 'hm'])
      setvermodal(true)
    }
  }, [opclick])

  const cerrarModal = () => {
    setvermodal(false)
    setopclick('')
    setdispositivo([])
  }
  return (
    <>
      
      {vermodal && dispotivo.length > 0 && (
        <ModalGenerico
          capa={1}
          width={opmodal[opclick][dispotivo[0]]}
          height={opmodal[opclick][dispotivo[1]]}
          titulo={opmodal[opclick].t}
          cerrarmodal={cerrarModal}
        >
          {['mpp'].includes(opclick) && <AEProductProd param={param} modo={opclick} />}
          
        </ModalGenerico>
      )}
      <div id='contenedor' style={e.contenedor(esComputadora)}>
        <div id='titulo' style={e.titulo(esComputadora)}>
          Productos de Producción
        </div>
        <div style={e.maestro(esComputadora)}>
          <Opciones
            style={{}}
            valorInicial={''}
            id='listaDistProductos'
            formPersona='entra'
            moderno='Maestros'
            opciones={maestros !== null ? maestros : []}
            clave='usuario'
            valor={['nombre', 'apellido_paterno']}
            esComputadora={esComputadora}
            onClick={e => {
              setmaestroselec(e)
            }}
          />
        </div>
        <e.Filtros id='filtros' $esComputadora={esComputadora}>
          <div style={{ padding: '0.1vw' }}>
            <Input
              style={{}}
              textoStyle={{}}
              id='fproducto'
              moderno={'Producto'}
              tipo='text'
              onChange={e => {
                setfproducto(
                  e.currentTarget.value
                    .toUpperCase()
                    .trim()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toUpperCase()
                )
                //filtrosNomApe(e.currentTarget.value)
              }}
              valorInicial={''}
              esComputadora={esComputadora}
              limpiar={fproducto === '' ? true : false}
            />
          </div>
          <div style={{ padding: '0.1vw' }}>
            <Input
              style={{}}
              textoStyle={{}}
              id='fcategoria'
              moderno={'Categoría'}
              tipo='text'
              onChange={e => {
                setfcategoria(e.currentTarget.value.toUpperCase().trim())
                //filtrosNomApe(e.currentTarget.value)
              }}
              valorInicial={''}
              esComputadora={esComputadora}
              limpiar={fcategoria === '' ? true : false}
            />
          </div>
          <div style={{ padding: '0.1vw' }}>
            <Input
              style={{}}
              textoStyle={{}}
              id='fprecio'
              moderno={'precio ≥ a'}
              tipo='number'
              onChange={e => {
                setfprecio(e.currentTarget.value.toUpperCase().trim())
                //filtrosNomApe(e.currentTarget.value)
              }}
              valorInicial={''}
              esComputadora={esComputadora}
              limpiar={fprecio === '' ? true : false}
            />
          </div>
          <div
            style={{
              padding: '0.1vw',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Boton
              style={{
                backgroundColor: '#E12121',
                borderRadius: esComputadora ? '0.5vw' : '1vw',
                padding: esComputadora ? '0.8vw' : '1vw'
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: esComputadora ? '1vw' : '2.3vw',
                padding: '0'
              }}
              texto='Limpiar Filtros'
              tipo='submit'
              estaProcesando={false}
              onClick={event => {
                event.preventDefault()
                //setactualizo(e => !e)
                setfproducto('')
                setfcategoria('')
                setfprecio('')
              }}
            />
          </div>
        </e.Filtros>
        <e.Contenido
          $esComputadora={esComputadora}
          $registroselec={registroselec}
        >
          {productos !== null &&
            productos.map(e => (
              <div key={e.categoria.categoria} className='categorias'>
                <h1 className='cabecera'>{e.categoria.descripcion}</h1>
                <div className='cuerpo'>
                  <p className='encabezados'>
                    <span>Producto</span>
                    <span>Cant. Coches</span>
                    <span>Cant. Latas</span>
                    <span>Cant. Prod x Latas</span>
                    <span>Cant. Paquetes</span>
                    <span>Precio de Producción</span>
                    <span>Opciones</span>
                  </p>
                  {e.productos.map(ep => (
                    <div className='registros' key={ep.producto}>
                      <p
                        className={'c' + ep.producto}
                        id={ep.producto}
                        dsc={fg.capitalizar_palabra(ep.descripcion)}
                        onClick={e => {
                          setparam({
                            ...param,
                            nombreprod: e.currentTarget.getAttribute('dsc'),
                            codigoprod: e.currentTarget.id
                          })
                          if ('c' + ep.producto === registroselec) {
                            setregistroselec('')
                          } else {
                            setregistroselec('c' + ep.producto)
                          }
                        }}
                      >
                        <span>{fg.capitalizar_palabra(ep.descripcion)}</span>
                        <span>{ep.coches}</span>
                        <span>{ep.latas}</span>
                        <span>{ep.canProductos}</span>
                        <span>{ep.Paquetes}</span>
                        <span>{ep.precio}</span>
                        <span>
                          <button
                            onClick={e => {
                              setopclick('mpp')
                              const temp = e.currentTarget.parentNode.parentNode
                              setparam({
                                ...param,
                                nombreprod: temp.getAttribute('dsc'),
                                codigoprod: temp.id
                              })
                            }}
                          >
                            <img className='img' src={editar} />
                          </button>
                          <button
                            onClick={(e) => {
                              setopclick('epp')
                              const temp = e.currentTarget.parentNode.parentNode
                              console.log(temp)
                              setparam({
                                ...param,
                                nombreprod: temp.getAttribute('dsc'),
                                codigoprod: temp.id
                              })
                            }}
                          >
                            <img className='img' src={borrar} />
                          </button>
                        </span>
                      </p>
                      <div className={'maestros pterminado c' + ep.producto}>
                        <div
                          style={{
                            display: 'grid',
                            width: esComputadora ? '10vw' : '15vw'
                          }}
                        >
                          <Boton
                            style={{
                              backgroundColor: '#528d25',
                              borderRadius: esComputadora
                                ? '0vw 0.5vw 0.5vw 0vw'
                                : '0vw 1vw 1vw 0vw',

                              marginBottom: '0.5vw'
                            }}
                            textoStyle={{
                              color: 'white',
                              width: '100%',
                              fontSize: esComputadora ? '1vw' : '2vw',
                              fontWeight: 'bolder',
                              padding: '0',
                              margin: '0'
                            }}
                            texto='Relacionar Maestro'
                            tipo='submit'
                            estaProcesando={false}
                            id='relprevinsumo'
                            onClick={e => {
                              setopclick('rmp')
                              //setTipoAccion(e.currentTarget.id)
                            }}
                          />
                          <Boton
                            style={{
                              backgroundColor: '#ECAB00',
                              borderRadius: esComputadora
                                ? '0vw 0.5vw 0.5vw 0vw'
                                : '0vw 1vw 1vw 0vw'
                              //padding: esComputadora ? '0.8vw' : '2vw'
                            }}
                            textoStyle={{
                              color: 'black',
                              width: '100%',
                              fontSize: esComputadora ? '1vw' : '2vw',
                              fontWeight: 'bolder'
                            }}
                            texto='Relacionar Producto'
                            tipo='submit'
                            estaProcesando={false}
                            id='relprevinsumo'
                            onClick={e => {
                              setopclick('rpp')
                              //setTipoAccion(e.currentTarget.id)
                            }}
                          />
                        </div>

                        <div className='conttablas'>
                          <table className='maestrostabla'>
                            <caption>Maestros</caption>
                            <thead>
                              <tr>
                                <th>Nombre</th>
                                <th title='Nro. de Documento'>Nro. Doc</th>
                                <th title='Celular'>Cel.</th>
                                <th>Pago</th>
                                <th>opciones</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>r1</td>
                                <td>r2</td>
                                <td>r3</td>
                                <td>r3</td>
                                <td>
                                  <button
                                    onClick={e => {
                                      setopclick('mmr')
                                    }}
                                  >
                                    <img className='img' src={editar} />
                                  </button>
                                  <button
                                    onClick={() => {
                                      setopclick('emr')
                                    }}
                                  >
                                    <img className='img' src={borrar} />
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table className='tproductotabla'>
                            <caption>Producto Terminado</caption>
                            <thead>
                              <tr>
                                <th>Nom. Prod.</th>
                                <th>% de influencia</th>
                                <th>opciones</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>r1</td>
                                <td>r2</td>
                                <td>
                                  <button
                                    onClick={() => {
                                      setopclick('mpr')
                                    }}
                                  >
                                    <img className='img' src={editar} />
                                  </button>
                                  <button
                                    onClick={() => {
                                      setopclick('epr')
                                    }}
                                  >
                                    <img className='img' src={borrar} />
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </e.Contenido>
        <div style={e.opciones(esComputadora)}>
          <div>
            <Boton
              style={{
                backgroundColor: '#2D8B0B',
                borderRadius: esComputadora ? '0.5vw' : '1vw',
                padding: esComputadora ? '0.8vw' : '1vh 2vw'
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: esComputadora ? '1vw' : '2.5vw'
              }}
              texto='Agregar Relación'
              tipo='submit'
              estaProcesando={false}
              id='agregarinsumo'
              onClick={e => {
                //setTipoAccion(e.currentTarget.id)
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductosProd
